import { ISharedEntityComponentConfig } from '../interfaces/shared-entity';
import { IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { Injectable } from '@angular/core';
import { IEntity } from '@mt-ng2/entity-list-module';
import { IMetaItemListDefinition, IIsMetaItemService } from '@mt-ng2/base-service';
import { SharedEntitiesService } from './shared-entities.library';

export class SharedEntityComponentConfig<T extends IEntity> {
    protected metaItemLists: IMetaItemListDefinition[] = [];

    constructor(public EmptyEntity: T, public EntityName: string, public MetaItemServices: any[]) {}

    setMetaItemLists(lists: IMetaItemListDefinition[]): void {
        this.metaItemLists = lists;
    }

    protected getMetaItemValues(serviceName: string): any[] {
        let list = this.metaItemLists.find((item) => item.ServiceName === serviceName);
        return list.Items;
    }
}

export class BlankSharedEntityConfig implements ISharedEntityComponentConfig<IEntity> {
    EmptyEntity: IEntity;
    EntityName: string;
    MetaItemServices: IIsMetaItemService[];

    constructor() {
        this.EmptyEntity = { Id: 0 };
        this.EntityName = '';
        this.MetaItemServices = [];
    }

    setMetaItemLists(lists: IMetaItemListDefinition[]): void {}

    getFormValues(entity: IEntity, formValue: IEntity): IEntity {
        return { ...this.EmptyEntity };
    }

    getDynamicFormConfig(entity: IEntity): IDynamicFormConfig {
        return { formObject: [], viewOnly: [] };
    }
}
/*
    This used to be a separate service, which we are including for backwards compatibility.
*/

export class SharedEntityService<T extends IEntity> extends SharedEntitiesService<T> {}
