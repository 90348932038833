import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ISelectionChangedEvent } from '@mt-ng2/multiselect-control';

export interface ISelectedItemsEvent {
    selectedItems: any[];
}

export class MtSearchFilterItem {
    /* istanbul ignore next */
    constructor(public Item: any, public Selected: boolean) {}
}

@Component({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MtSearchFilterSelectComponent),
        },
    ],
    selector: 'mt-search-filter-select',
    template: `
        <multiselect
            [items]="items"
            [filterMode]="true"
            [entity]="entity"
            [showSelectAllButtons]="showSelectAllButtons"
            [maxToShowInSelectedText]="maxToShowInSelectedText"
            [disabled]="disabled"
            [autoClose]="autoClose"
            (onSelectionChanged)="multiSelectSelectionChanged($event)"
            (blur)="handleBlur($event)"
            (focus)="handleFocus($event)"
        >
        </multiselect>
    `,
})
export class MtSearchFilterSelectComponent implements ControlValueAccessor {
    // *** CONTROL VALUE ACCESSOR ***
    /* tslint:disable:member-ordering */
    writeValue(obj: any): void {
        this.handleWriteValue(obj);
    }
    protected onValueChanged: (value: any) => void;
    registerOnChange(fn: any): void {
        this.onValueChanged = fn;
    }
    protected onTouched: () => void;
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    // *** END -- CONTROL VALUE ACCESSOR ***

    @Input() items: MtSearchFilterItem[];
    @Input() valueIdAccessor = 'Id';
    @Input() entity: string;
    @Input() showSelectAllButtons = true;
    @Input() maxToShowInSelectedText: number;
    @Input() disabled = false;
    @Input() autoClose: boolean | 'outside' | 'inside';

    @Output('onSelectionChanged') selectionChanged: EventEmitter<ISelectedItemsEvent> = new EventEmitter<ISelectedItemsEvent>();
    @Output('onSelectListClosed') selectListClosed: EventEmitter<ISelectedItemsEvent> = new EventEmitter<ISelectedItemsEvent>();
    @Output() blur: EventEmitter<any> = new EventEmitter<any>();
    @Output() focus: EventEmitter<any> = new EventEmitter<any>();

    protected selectedItems: any[];

    constructor() {}

    multiSelectSelectionChanged(event: ISelectionChangedEvent): void {
        this.selectedItems = event.selectedItems;
        const eventToEmit: ISelectedItemsEvent = {
            selectedItems: this.selectedItems,
        };
        this.selectionChanged.emit(eventToEmit);
        this.updateValue();
    }

    protected updateValue(): void {
        if (!this.onValueChanged) {
            return;
        }
        let value = this.selectedItems;
        if (value?.length && this.valueIdAccessor && value[0][this.valueIdAccessor]) {
            value = value.map((item) => item[this.valueIdAccessor]);
        }
        value = value?.length ? value : null; // set to null if no values in array
        this.onValueChanged(value);
    }

    protected handleWriteValue(value: any[]): void {
        this.items.forEach((item) => {
            if (!value?.length) {
                item.Selected = false;
            } else if (this.valueIdAccessor) {
                item.Selected = value.some((id) => item.Item[this.valueIdAccessor] === id);
            } else {
                item.Selected = value.some((valueItem) => JSON.stringify(item.Item) === JSON.stringify(valueItem));
            }
        });
    }

    handleFocus(event: Event): void {
        this.focus.emit(event);
        this.onTouched?.();
    }

    handleBlur(event: Event): void {
        this.blur.emit(event);
        const eventToEmit: ISelectedItemsEvent = {
            selectedItems: this.selectedItems,
        };
        this.selectListClosed.emit(eventToEmit);
    }
}
