import { CanActivate, Router, ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { AccessRequestsModuleConfigService } from '@mt-ng2/access-requests-module-config';

import { AuthService } from '../services/auth.service';
import { ClaimsService, ClaimValues } from '../services/claims.service';
import { AuthConfig } from '../libraries/auth-config.library';
import { Injector } from '@angular/core';

/**
 * Object for holding data about if the user has
 * access to the route they are trying to activate
 *  @property claimType
 *  @property claimValues
 *  @property isHomePage
 */
export interface IRoleGuarded {
    claimType: number | number[];
    claimValues: ClaimValues[];
    isHomePage?: boolean;
}

export class MtAuthGuard implements CanActivate {
    public static readonly Return_Url_QueryParam = 'returnUrl';

    public window = window;

    public authService: AuthService;
    public claimsService: ClaimsService;
    public router: Router;
    public notificationsService: NotificationsService;
    public authConfig: AuthConfig;
    public accessRequestsModuleConfigService: AccessRequestsModuleConfigService;

    constructor(injector: Injector) {
        this.authService = injector.get(AuthService);
        this.claimsService = injector.get(ClaimsService);
        this.router = injector.get(Router);
        this.notificationsService = injector.get(NotificationsService);
        this.authConfig = injector.get(AuthConfig);
        this.accessRequestsModuleConfigService = injector.get(AccessRequestsModuleConfigService);
    }

    /**
     * Function to determine if the route can be activated.
     * It will set the profile path, check if the user is
     * authenticated and check if their role has access to this route.
     * @param route
     */
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        return forkJoin([
            this.authService.isAuthenticated(), // is logged in and has a valid token
            this.accessRequestsModuleConfigService.accessRequestsEnabled.pipe(take(1)), // is accessRequests feature enabled
        ])
            .pipe(
                map(([isAuthenticated, accessRequestsEnabled]) => {
                    // user not signed in, send to login page
                    if (!isAuthenticated) {
                        if (!this.router.url.startsWith(this.authConfig.paths.loginPath)) {
                            this.notificationsService.info(this.authConfig.messages.requiresLoginMessage);
                            const attemptedUrl = this.window.location.href.split('/#')[1];
                            let loginUrl = this.authConfig.paths.loginPath;
                            if (attemptedUrl) {
                                loginUrl += `?${MtAuthGuard.Return_Url_QueryParam}=${attemptedUrl}`;
                            }
                            return this.router.parseUrl(loginUrl);
                        }
                        return false;
                    }
                    // now we know the user is signed in
                    // do we need to check for user role permission for this route?
                    const roleGuard = this.getRoleGuard(route);
                    if (roleGuard) {
                        let passedRoleGuardCheck = false;
                        if (Array.isArray(roleGuard.claimType)) {
                            for (const claimType of roleGuard.claimType) {
                                passedRoleGuardCheck = this.claimsService.hasClaim(claimType, roleGuard.claimValues);
                                if (passedRoleGuardCheck) {
                                    break;
                                }
                            }
                        } else {
                            passedRoleGuardCheck = this.claimsService.hasClaim(roleGuard.claimType, roleGuard.claimValues);
                        }
                        if (!passedRoleGuardCheck) {
                            return this.handleFailedRoleGuardCheck(accessRequestsEnabled, roleGuard);
                        }
                    }
                    // if (roleGuard) {
                    //     const passedRoleGuardCheck = this.claimsService.hasClaim(roleGuard.claimType, roleGuard.claimValues);
                    //     if (!passedRoleGuardCheck) {
                    //         return this.handleFailedRoleGuardCheck(accessRequestsEnabled, roleGuard);
                    //     }
                    // }
                    // passed all checks
                    return true;
                }, this),
            )
            .pipe(
                catchError((error) => {
                    this.notificationsService.info(this.authConfig.messages.requiresLoginMessage);
                    return of(this.router.parseUrl(this.authConfig.paths.loginPath));
                }),
            );
    }

    getRoleGuard(route: ActivatedRouteSnapshot): IRoleGuarded {
        let isRoleGuarded = route.data && route.data.claimType && route.data.claimValues ? true : false;
        return isRoleGuarded ? <IRoleGuarded>route.data : null;
    }

    handleFailedRoleGuardCheck(accessRequestsEnabled: boolean, roleGuard: IRoleGuarded): boolean | UrlTree {
        if (accessRequestsEnabled) {
            // If access requests are enabled and the user tried to reach reach a page they do
            // not have access to via RoleGuard, send the user to the no-access-page
            const claimType = Array.isArray(roleGuard.claimType) ? roleGuard.claimType[0] : roleGuard.claimType;
            const routeUrl = `${this.accessRequestsModuleConfigService.noAccessPageUrl}/${claimType}`;
            // const routeUrl = `${this.accessRequestsModuleConfigService.noAccessPageUrl}/${roleGuard.claimType}`;
            return this.router.parseUrl(routeUrl);
        } else if (roleGuard.isHomePage) {
            // if this is the home page, redirect to their profile path
            return this.router.parseUrl(this.authConfig.paths.myProfilePath);
        } else {
            // else we need to post a message to notify the user
            this.notificationsService.error(this.authConfig.messages.missingPermissionMessage);
            return this.router.parseUrl(this.authConfig.paths.homePath);
        }
    }
}
