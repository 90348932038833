import { Component, ContentChild, Injector, TemplateRef } from '@angular/core';

import { NavService } from '../nav.service';

@Component({
    selector: `header-shortcut-help`,
    styles: [
        `
            .shortcut-help {
                cursor: pointer;
                padding-left: 8px;
                padding-right: 8px;
            }
            .shortcut-help .help-text {
                font-size: 11px;
                vertical-align: text-bottom;
            }
        `,
    ],
    styleUrls: [`./nav-header.styles.less`],
    template: `
        <ng-container
            *ngTemplateOutlet="
                templateRef;
                context: { openShortcutHelp: this.openShortcutHelp.bind(this), showShortcutHelpButton: this.showShortcutHelpButton }
            "
        >
        </ng-container>
        <div
            *ngIf="showShortcutHelpButton && !templateRef"
            class="nav navbar-nav nav-header-button shortcut-help"
            title="Keyboard Shortcuts"
            (click)="openShortcutHelp()"
        >
            <i class="fa fa-info-circle" aria-hidden="true"></i>
            <span class="hidden-xs hidden-sm help-text"> Shortcuts </span>
        </div>
    `,
})
export class HeaderShortcutHelpComponent {
    @ContentChild(TemplateRef) templateRef: any;

    get showShortcutHelpButton(): boolean {
        return this.navService.showKeyboardShortcutsButton && this.navService.hasKeyboardShortcutsModule;
    }

    protected navService: NavService;

    constructor(injector: Injector) {
        this.navService = injector.get(NavService);
    }

    openShortcutHelp(): void {
        this.navService.keyboardShortcutHelpWindowService.showHelpWindow();
    }
}
