import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-nav',
    template: ` <app-nav-header></app-nav-header>
        <app-nav-sidebar></app-nav-sidebar>`,
})
export class NavComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
