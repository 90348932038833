import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { throwError as observableThrowError, BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { tap, map, catchError, switchMap, mergeMap, take } from 'rxjs/operators';

import { isNull, isNullOrWhitespace } from '@mt-ng2/common-functions';
import { EnvironmentService } from '@mt-ng2/environment-module';

import { TokenService } from './token.service';
import { WINDOW } from '../libraries/window-factory.library';
import { ILoggedIn, ILoginResponse, IGoogleLoginObject, IUserDetails, ILoggedInUpdatableValues } from '../models/auth-service.models';
import { getDefaultLoggedInUser, matchPassword, getLoggedInFromToken } from '../libraries/auth-service.library';
import { OnLogoutHandler } from '../models/auth-module-config';
import { ILoginToken, mapToken } from '../libraries/token-service.library';
import { handlerExecuteSafe } from '../libraries/handler.library';
import { AuthConfig } from '../libraries/auth-config.library';
import { OnLoginHandler } from '../models/auth-module-config';
import { BehaviorSubjectWithoutNext } from '../libraries/behavior-subject-without-next.library';
import { AuthenticationResult } from '@azure/msal-browser';

/**
 * Angular service responsible for handling login, authentication, storage and retrieval of tokens,
 * and the currently logged in user.
 */
@Injectable()
export class AuthService {
    /**
     * Observable of the currently logged in user.
     */
    private _currentUser = new BehaviorSubject<ILoggedIn>(getDefaultLoggedInUser());
    private _currentUserWithoutNext: BehaviorSubjectWithoutNext<ILoggedIn>;
    get currentUser(): BehaviorSubjectWithoutNext<ILoggedIn> {
        if (!this._currentUserWithoutNext) {
            this._currentUserWithoutNext = new BehaviorSubjectWithoutNext<ILoggedIn>(this._currentUser);
        }
        return this._currentUserWithoutNext;
    }

    /**
     * Observable of the current app ready state.
     * The app is considered ready when the current user state has been established and verified via token.
     *
     * one of:
     * - `null` app not ready, waiting/attempting to verify state of current user
     * - `true` app is ready
     */
    appReady: BehaviorSubject<boolean>;

    private _refreshTokenInProgress = false;
    private _tokenRefreshed$ = new Subject<ILoginToken>();

    constructor(
        protected environmentService: EnvironmentService,
        protected http: HttpClient,
        protected tokenService: TokenService,
        protected router: Router,
        @Optional() @Inject(WINDOW) private _window: any,
        private authConfig: AuthConfig,
    ) {
        this.appReady = new BehaviorSubject<boolean>(null);
        this.getToken().subscribe(
            (token) => {
                if (token && token.name) {
                    this._updateCurrentUser(getLoggedInFromToken(token));
                }
            },
            () => {},
        );
    }

    /**
     * Calls the user authentication endpoint and returns a login response.  If the login
     * was successful, it also saves the token and updates the currentUser property.
     * @param {string} username
     * @param {string} password
     * @param {boolean} remember
     * @returns {Observable<ILoginResponse>} the login response
     */
    login(username: string, password: string, remember: boolean): Observable<ILoginResponse> {
        return this.tokenService.getDeviceId(username).pipe(
            switchMap((deviceId) => {
                const data: any = {
                    AuthClientID: this.environmentService.config.authClientId,
                    AuthClientSecret: this.environmentService.config.authSecretVariable,
                    DeviceGuid: deviceId,
                    Password: password,
                    Username: username,
                };
                return this.http.post<ILoginResponse>('/authUsers/token', data).pipe(tap(this.getPossible2faLoginHandler(username, deviceId).bind(this)));
            }),
        );
    }

    twoFactorLogin(otp: string, rememberDevice: boolean): Observable<ILoginResponse> {
        return this.tokenService.get2faInfoFromCookie().pipe(
            switchMap((info) => {
                if (!info) {
                    return observableThrowError(new Error('2fa cookie must not be null'));
                }
                const deviceId = this.tokenService.refreshDeviceId(info.Username);
                const data: any = {
                    AuthClientID: this.environmentService.config.authClientId,
                    AuthClientSecret: this.environmentService.config.authSecretVariable,
                    AuthUserId: info.AuthUserId,
                    OneTimePassword: otp,
                    RememberDevice: rememberDevice,
                    RememberDeviceGuid: deviceId,
                    ValidateDeviceGuid: info.DeviceGuid,
                };
                return this.http.post<ILoginResponse>('/authUsers/2fa', data);
            }),
            tap(this.handleLogin.bind(this)),
        );
    }

    /**
     * Calls the google user authentication endpoint and returns a login response.  If the login
     * was successful, it also saves the token and updates the currentUser property.
     * @param {IGoogleLoginObject} googleLoginObject Object containing data provided by google login.  Created automatically by functionality in login package.
     * @returns {Observable<ILoginResponse>} the login response
     */
    googleLogin(googleLoginObject: IGoogleLoginObject): Observable<ILoginResponse> {
        const data: any = {
            AuthClientID: this.environmentService.config.authClientId,
            AuthClientSecret: this.environmentService.config.authSecretVariable,
            Email: googleLoginObject.email,
            FirstName: googleLoginObject.firstName,
            LastName: googleLoginObject.lastName,
            Token: googleLoginObject.token,
        };
        return this.http.post<ILoginResponse>('/authUsers/google/token', data).pipe(tap(this.handleLogin.bind(this)));
    }

    /**
     *
     * @param microsoftLoginObject Object containing data provided by microsoft login.  Created automatically by Microsoft popup in login package.
     * @returns {Observable<ILoginResponse>} the login response
     */
    microsoftLogin(microsoftLoginObject: AuthenticationResult): Observable<ILoginResponse> {
        const data: any = {
            AuthClientId: this.environmentService.config.authClientId,
            AuthClientSecret: this.environmentService.config.authSecretVariable,
            Email: microsoftLoginObject.account.username,
            Token: microsoftLoginObject.accessToken,
        };
        return this.http.post<ILoginResponse>('/auth/microsoft', data).pipe(tap(this.handleLogin.bind(this)));
    }

    private getPossible2faLoginHandler(username: string, deviceId: string): (response: ILoginResponse) => void {
        return (response) => {
            if (response.AuthUserId) {
                this.tokenService.save2faInfoInCookie({
                    AuthUserId: response.AuthUserId,
                    DeviceGuid: deviceId,
                    Username: username,
                });
            } else {
                this.handleLogin(response);
            }
        };
    }

    private handleLogin(response: ILoginResponse): void {
        this.saveToken(response, true);
        const loginHandler: OnLoginHandler = this.authConfig.eventHandlers.onLogin;
        if (loginHandler) {
            loginHandler(response);
        }
    }

    /**
     * Clears out all tokens, updates the currentUser to be the default user, and either reloads the page
     * or navigates to the login route based on the bypassRoute param.  Fires OnLogout and OnBeforeLogout events
     * if handlers provided in the auth config.
     * @param {boolean} bypassRoute flag that if passed in as true, will reload the current window.
     * If not passed in or passed in as false, will navigate to the login route.
     */
    logout(bypassRoute?: boolean): void {
        const logoutDetails = this.getLogoutDetails();
        handlerExecuteSafe(logoutDetails.onBeforeLogoutHandler, () =>
            this.processLogout(logoutDetails.currentUser, bypassRoute, logoutDetails.onLogout),
        );
    }

    /**
     * gets details for the logout process, including current user info and auth config event handlers.
     */
    private getLogoutDetails(): { currentUser: IUserDetails; onBeforeLogoutHandler: Observable<void>; onLogout: OnLogoutHandler } {
        const currentUser = this.currentUser.getValue();
        const currentUserDetails = {
            CustomOptions: currentUser.CustomOptions,
            Email: currentUser.Email,
            Id: currentUser.Id,
            Name: currentUser.Name,
            ProfileImagePath: currentUser.ProfileImagePath,
        };
        return {
            currentUser: currentUserDetails,
            onBeforeLogoutHandler:
                (this.authConfig.eventHandlers.onBeforeLogout && this.authConfig.eventHandlers.onBeforeLogout(currentUserDetails)) || null,
            onLogout: (this.authConfig && this.authConfig.eventHandlers.onLogout) || null,
        };
    }

    private processLogout(currentUserDetails?: IUserDetails, bypassRoute?: boolean, onLogout?: OnLogoutHandler): void {
        this.tokenService.clearTokens();
        this._currentUser.next(getDefaultLoggedInUser());

        // after logout was successful, fire onLogout event handler if present
        if (onLogout) {
            onLogout(currentUserDetails);
        }

        // I would rather use the router here, but we run into issues
        // when this gets called from inside an interceptor like it does
        // in the catch of the refresh interceptor on a refresh route.
        // I found this issue so maybe we can revamp this once this is resolved.
        // https://github.com/angular/angular/issues/21149
        if (bypassRoute) {
            this._window.location.reload();
        } else {
            this.router.navigate(['/login']);
        }
    }

    /**
     * Gets Observable that determines if there is a current user with a valid token.
     * @returns {Observable<boolean>}
     */
    isAuthenticated(): Observable<boolean> {
        return this.getToken().pipe(
            catchError(() => of(null)),
            mergeMap((result) => of(result ? true : false)),
        );
    }

    /**
     * Gets the current users token.  If there is no current user token, this will
     * throw an error.
     * @returns {Observable<ILoginToken>}
     */
    getToken(): Observable<ILoginToken> {
        return this.tokenService.getTokenFromCookie().pipe(
            switchMap((tokenString) => {
                return this.validateToken(tokenString);
            }),
        );
    }

    /**
     * Gets the current users token.  If there is no current user token, will return
     * null instead.
     * @returns {Observable<ILoginToken>}
     */
    getLoginTokenFromCookie(): Observable<ILoginToken> {
        return this.tokenService.getTokenFromCookie().pipe(map((tokenString) => (tokenString ? (JSON.parse(tokenString) as ILoginToken) : null)));
    }

    /**
     * Validates a tokenString returning the LoginToken from that string.  Throws an error if the tokenString is null.
     * @param {string} tokenString
     * @returns {Observable<ILoginToken>}
     */
    private validateToken(tokenString: string): Observable<ILoginToken> {
        if (isNull(tokenString)) {
            return observableThrowError(new Error('null token'));
        }
        const token: ILoginToken = JSON.parse(tokenString);
        this.appReady.next(true);
        return of(token);
    }

    /**
     * Gets the current xsrf token.  If there is no current xsrf token, this will
     * throw an error.
     * @returns {Observable<string>}
     */
    getXsrfToken(): Observable<string> {
        return this.tokenService.getXsrfTokenFromCookie().pipe(
            switchMap((tokenString) => {
                if (isNullOrWhitespace(tokenString)) {
                    return observableThrowError(new Error('null token'));
                } else {
                    return of(tokenString);
                }
            }),
        );
    }

    /**
     * Safe refresh token, that only tries to refresh once even if called multiple times while refreshing.
     */
    refreshToken(): Observable<ILoginToken> {
        if (this._refreshTokenInProgress) {
            return this._tokenRefreshed$.asObservable().pipe(take(1));
        } else {
            this._refreshTokenInProgress = true;

            return this._refreshTokenCall().pipe(
                tap((loginToken) => {
                    this._refreshTokenInProgress = false;
                    this._tokenRefreshed$.next(loginToken);
                }),
            );
        }
    }

    /**
     * Call the refresh endpoint to auto refresh the token
     */
    private _refreshTokenCall(): Observable<ILoginToken> {
        return this.getLoginTokenFromCookie().pipe(
            mergeMap((currentToken) => {
                const data: any = {
                    AuthClientID: this.environmentService.config.authClientId,
                    AuthClientSecret: this.environmentService.config.authSecretVariable,
                    AuthUserId: currentToken.authUserId,
                    TokenIdentifier: currentToken.refreshId,
                };
                return this.http
                    .post<ILoginResponse>('/authUsers/refresh', data, {
                        headers: { BypassAuth: 'true' },
                    })
                    .pipe(
                        tap((response) => this.saveToken(response, currentToken.remember)),
                        map((response) => mapToken(response, currentToken.remember)),
                    );
            }),
        );
    }

    /**
     * Update the current user info and save the token locally
     * @param data
     * @param remember
     */
    saveToken(data: ILoginResponse, remember: boolean): void {
        // save current user info
        const token = mapToken(data, remember);
        this._updateCurrentUser(getLoggedInFromToken(token));
        this.tokenService.saveJwtTokenInCookie(token);
        const xsrfToken = data.LoginResult.CsrfToken;
        this.tokenService.saveXsrfTokenInCookie(xsrfToken, remember);
    }

    private _updateCurrentUser(updatedUser: ILoggedIn | Partial<ILoggedInUpdatableValues>): void {
        const currentUser = this.currentUser.getValue();

        (updatedUser as ILoggedIn).Id = (updatedUser as ILoggedIn).Id || currentUser.Id;
        Object.assign(currentUser, updatedUser);

        this._currentUser.next(currentUser);
    }

    private _updateCurrentUserInToken(currentUser: ILoggedIn): void {
        // update login token in cookie
        this.getLoginTokenFromCookie().subscribe((token) => {
            if (!token) {
                return;
            }
            token.customOptions = currentUser.CustomOptions;
            token.name = currentUser.Name;
            token.email = currentUser.Email;
            token.img = currentUser.ProfileImagePath || '';
            this.tokenService.saveJwtTokenInCookie(token);
        });
    }

    /**
     * update the current user value, emitting the new value through the currentUser Observable
     * and update the token in the cookie, so these changes persist.  Pass in only the values
     * that should be changed.
     * @example
     * this.authService.updateCurrentUser({ Name: 'Joe Smith' });
     * @param userValuesToUpdate
     */
    public updateCurrentUser(userValuesToUpdate: Partial<ILoggedInUpdatableValues>): void {
        // ensure the passed in object only has the allowed properties, so that if the param
        // was passed in using any, we are still sure this object doesn't contain other properties
        userValuesToUpdate = this.limitUpdatableUserProps(userValuesToUpdate);

        this._updateCurrentUser(userValuesToUpdate);

        this._updateCurrentUserInToken(this.currentUser.getValue());
    }

    private limitUpdatableUserProps(value: Partial<ILoggedInUpdatableValues>): Partial<ILoggedInUpdatableValues> {
        const newValue: Partial<ILoggedInUpdatableValues> = {};
        if (value.CustomOptions !== undefined) {
            newValue.CustomOptions = value.CustomOptions;
        }
        if (value.Email !== undefined) {
            newValue.Email = value.Email;
        }
        if (value.Name !== undefined) {
            newValue.Name = value.Name;
        }
        if (value.ProfileImagePath !== undefined) {
            newValue.ProfileImagePath = value.ProfileImagePath;
        }
        return newValue;
    }

    /**
     * Generate a forgot password link
     * @param email
     */
    forgot(email: string): Observable<object> {
        const data: any = {
            email: email,
        };
        return this.http.post('/users/forgot', data, {
            headers: { BypassAuth: 'true' },
        });
    }

    /**
     * Generate a forgot password link
     * @param resetKey
     */
    adminAccess(resetKey: string): Observable<ILoginResponse> {
        const data: any = {
            AuthClientID: this.environmentService.config.authClientId,
            AuthClientSecret: this.environmentService.config.authSecretVariable,
            ResetKey: resetKey,
        };
        return this.http
            .post<ILoginResponse>('/authUsers/adminAccess', data, {
                headers: { BypassAuth: 'true' },
            })
            .pipe(tap((response) => this.saveToken(response, false)));
    }

    resetPassword(userId: number, password: string, confirmPassword: string, resetKey: string): Observable<ILoginResponse> {
        const data: any = {
            AuthClientID: this.environmentService.config.authClientId,
            AuthClientSecret: this.environmentService.config.authSecretVariable,
            AuthUserId: userId,
            Confirmation: confirmPassword,
            Password: password,
            ResetKey: resetKey,
        };
        return this.http
            .post<ILoginResponse>('/authUsers/reset', data, {
                headers: { BypassAuth: 'true' },
            })
            .pipe(tap((response) => this.saveToken(response, false)));
    }

    matchPassword(form: FormGroup, formname = 'AuthUser'): boolean {
        return matchPassword(form, formname);
    }
}
