import { CropperSettings, ICropperSettings } from 'ngx-img-cropper';
import {
    getSettingsFromConfigs,
    IPhotoControlModuleConfig,
    PhotoControlModuleConfigToken,
    IMtCropperSettings,
    Shape,
} from '@mt-ng2/photo-control-config';
import { globalInjector } from './global-injector.library';

export class MtCropperSettingsHelper {
    public static getNgxImageCropperSettings(componentLevelCropperSettings: IMtCropperSettings = null): ICropperSettings {
        const photoControlConfig = globalInjector.get(PhotoControlModuleConfigToken, null) as IPhotoControlModuleConfig;
        const moduleLevelCropperSettings = (photoControlConfig && photoControlConfig.cropperSettings) || null;
        let cropperSettings = getSettingsFromConfigs(moduleLevelCropperSettings, componentLevelCropperSettings);
        cropperSettings = Object.assign({}, defaultMtCropperSettings, cropperSettings);

        const ngxImageCropperSettings = this.transformSettingsToNgxCropperSettings(cropperSettings);
        return new CropperSettings(ngxImageCropperSettings);
    }

    private static transformSettingsToNgxCropperSettings(cropperSettings: IMtCropperSettings): ICropperSettings {
        const transformationKey = {
            cropFromOriginalResolution: 'minWithRelativeToResolution',
            hideFileInput: 'noFileInput',
            shape: 'rounded',
        };

        for (const prop in cropperSettings) {
            if (transformationKey.hasOwnProperty(prop)) {
                if (prop === 'shape') {
                    cropperSettings[transformationKey[prop]] = cropperSettings[prop] === Shape.Circle ? true : false;
                } else {
                    cropperSettings[transformationKey[prop]] = cropperSettings[prop];
                }
                delete cropperSettings[prop];
            }
        }

        return cropperSettings;
    }
}

/* tslint:disable:object-literal-sort-keys */
export const defaultMtCropperSettings: IMtCropperSettings = {
    canvasHeight: 300,
    canvasWidth: 500,
    croppedHeight: 200,
    croppedWidth: 200,
    height: 200,
    width: 200,
    minHeight: 100,
    minWidth: 100,
    cropFromOriginalResolution: false,
    hideFileInput: true,
    keepAspect: true,
    preserveSize: false,
    shape: Shape.Circle,
};
/* tslint:enable:object-literal-sort-keys */
