export interface IMicrosoftAuthConfig {
    additionalScopes?: Array<string>;
    clientId: string;
    authority: string;
    redirectUri: string;
}

export const Constants = {
    OFFLINE_ACCESS_SCOPE: 'offline_access',
    OPENID_SCOPE: 'openid',
    PROFILE_SCOPE: 'profile',
};

export const OPEN_IDENTITY_CONNECT_DEFAULT_SCOPES = [Constants.OPENID_SCOPE, Constants.PROFILE_SCOPE, Constants.OFFLINE_ACCESS_SCOPE];

export class MicrosoftAuthConfig implements IMicrosoftAuthConfig {
    public additionalScopes?: Array<string>;
    public clientId: string;
    public authority: string;
    public redirectUri: string;

    constructor(options: IMicrosoftAuthConfig) {
        Object.assign(this, options);
        // Add default scopes to passed in scopes if provided
        this.additionalScopes = options.additionalScopes?.concat(...OPEN_IDENTITY_CONNECT_DEFAULT_SCOPES) || OPEN_IDENTITY_CONNECT_DEFAULT_SCOPES;
    }
}
