import { InjectionToken } from '@angular/core';

/**
 * Token used for providing an override to the auth service to be used
 * in the login module
 * @example
 * // in the app or shared modules providers section
 * providers: [
 *     { provide: LoginModuleOverrideAuthServiceToken, useExisiting: LoginModuleOverridenAuthService },
 * ],
 */
export const LoginModuleOverrideAuthServiceToken = new InjectionToken<any>('login_override_auth_service');
