import { Component, Input, ViewChild, ElementRef, OnInit, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AccessRequestsModuleConfigService } from '@mt-ng2/access-requests-module-config';

import { ClaimsService } from '@mt-ng2/auth-module';
import { NavSidebarRowItem, NavSidebarParentRowItem } from '@mt-ng2/nav-module-config';
import { take } from 'rxjs/operators';
import { NavService } from '../nav.service';

@Component({
    selector: 'app-nav-menu-item',
    template: `
        <a *ngIf="ShowMe && isParentRow" class="nav-item" (click)="toggleExpand()">
            <ng-container *ngTemplateOutlet="rowItemTemplate"> </ng-container>
        </a>
        <a
            *ngIf="ShowMe && !isParentRow && hasKeyboardShortcutsModule"
            class="nav-item"
            [keyboardShortcut]="rowItem.shortcut"
            [routerLink]="rowItem.link"
        >
            <ng-container *ngTemplateOutlet="rowItemTemplate"> </ng-container>
        </a>
        <a *ngIf="ShowMe && !isParentRow && !hasKeyboardShortcutsModule" class="nav-item" [routerLink]="rowItem.link">
            <ng-container *ngTemplateOutlet="rowItemTemplate"> </ng-container>
        </a>
        <ng-template #rowItemTemplate>
            <div class="menu-img {{ rowItem.icon }}"></div>
            <span class="menu-title" [class.active]="rowItem.link ? router.isActive(rowItem.link, true) : null" style="white-space: normal">
                {{ rowItem.content }}
                <a
                    *ngIf="ShowSettings"
                    #setting
                    class="btn btn-nav btn-transparent pull-right"
                    [routerLink]="[rowItem.settingsLink]"
                    (click)="stopProp($event)"
                >
                    <i class="fa fa-fw fa-cog"></i>
                </a>
                <a
                    *ngIf="ShowAdd"
                    class="btn btn-nav btn-nav-on-hover btn-transparent pull-right"
                    [routerLink]="[rowItem.addLink]"
                    (click)="stopProp($event)"
                >
                    <i class="fa fa-fw fa-plus"></i>
                </a>
            </span>
        </ng-template>
    `,
})
export class NavMenuItemComponent implements OnInit {
    @ViewChild('setting') setting: ElementRef;
    @Input() rowItem: NavSidebarRowItem | NavSidebarParentRowItem;

    get hasKeyboardShortcutsModule(): boolean {
        return this.navService.hasKeyboardShortcutsModule;
    }

    accessRequestsEnabled: boolean;

    public router: Router;
    protected claimsService: ClaimsService;
    protected navService: NavService;
    protected accessRequestsModuleConfigService: AccessRequestsModuleConfigService;

    constructor(injector: Injector) {
        this.router = injector.get(Router);
        this.claimsService = injector.get(ClaimsService);
        this.navService = injector.get(NavService);
        this.accessRequestsModuleConfigService = injector.get(AccessRequestsModuleConfigService);
    }

    ngOnInit(): void {
        this.accessRequestsModuleConfigService.accessRequestsEnabled
            .pipe(take(1))
            .subscribe((accessRequestsEnabled) => (this.accessRequestsEnabled = accessRequestsEnabled));
    }

    get ShowSettings(): boolean {
        if (!this.rowItem.settingsLink || this.rowItem.settingsLink.length === 0) {
            return false;
        }

        if (this.accessRequestsEnabled) {
            return true;
        }

        return this.checkClaims(this.rowItem.claimType, this.rowItem.claimValues);
    }

    get ShowAdd(): boolean {
        if (!this.rowItem.addLink || this.rowItem.addLink.length === 0) {
            return false;
        }

        if (this.accessRequestsEnabled) {
            return true;
        }

        return this.checkClaims(this.rowItem.claimType, this.rowItem.claimValues);
    }
    get ShowMe(): boolean {
        if (this.accessRequestsEnabled) {
            return true;
        }
        return this.checkClaims(this.rowItem.claimType, this.rowItem.claimValues);
    }

    checkClaims(claimType: number | number[], claimValues: any[]): boolean {
        if (claimType && claimValues && claimValues.length > 0) {
            if (typeof claimType === 'number') {
                return this.claimsService.hasClaim(claimType, claimValues);
            } else if (Array.isArray(claimType)) {
                return claimType.some((type) => this.claimsService.hasClaim(type, claimValues));
            }
        }
        return true;
    }

    get isParentRow(): boolean {
        return this.rowItem && (<NavSidebarParentRowItem>this.rowItem).children ? true : false;
    }

    toggleExpand(): void {
        if (!this.isParentRow) {
            return;
        }
        const parentRowItem = this.rowItem as NavSidebarParentRowItem;
        if (parentRowItem.canToggleExpand) {
            parentRowItem.expanded = !parentRowItem.expanded;
        }
    }

    stopProp($event: MouseEvent): void {
        $event.stopPropagation();
        if (this.setting) {
            this.setting.nativeElement.blur();
        }
    }
}
