import { ISidebarCurrentUserDetailsApi } from './sidebar-current-user-details-api';
import { Component, ContentChild, TemplateRef, OnInit, OnDestroy, Injector } from '@angular/core';

import { NavService } from '../nav.service';
import { getProfileImgPathFromCurrentUser } from './nav-sidebar.library';
import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { EnvironmentService } from '@mt-ng2/environment-module';
import { Subscription } from 'rxjs';

@Component({
    selector: `sidebar-current-user-details`,
    styles: [``],
    template: `
        <ng-container *ngTemplateOutlet="templateRef; context: contextApi"> </ng-container>
        <div *ngIf="!templateRef" class="user-panel">
            <div class="pull-left image">
                <img [src]="userImageUrl" class="img-circle" alt="Profile Image" />
            </div>
            <div class="pull-left info">
                <p>{{ userName }}</p>
                <a [routerLink]="[myProfilePath]">Edit my Profile</a>
            </div>
            <i
                role="button"
                (click)="toggleSidebarCollapse()"
                class="fa fa-fw pull-right navbar-toggle hidden-md-down"
                [class.fa-arrow-right]="sidebarCollapsed"
                [class.fa-arrow-left]="!sidebarCollapsed"
            ></i>
        </div>
    `,
})
export class SidebarCurrentUserDetailsComponent implements OnInit, OnDestroy {
    sidebarCollapsed: boolean;
    userId: number;
    userName: string;
    userImageUrl: string;
    myProfilePath: string;

    contextApi: ISidebarCurrentUserDetailsApi;

    subscriptions: Subscription = new Subscription();

    @ContentChild(TemplateRef) templateRef: any;

    protected navService: NavService;
    protected authService: AuthService;
    protected environmentService: EnvironmentService;

    constructor(injector: Injector) {
        this.navService = injector.get(NavService);
        this.authService = injector.get(AuthService);
        this.environmentService = injector.get(EnvironmentService);
    }

    ngOnInit(): void {
        this.myProfilePath = this.navService.myProfilePath;

        this.setCurrentUserVariables(this.authService.currentUser.getValue());
        this.subscriptions.add(this.authService.currentUser.subscribe((currentUser: any) => this.setCurrentUserVariables(currentUser)));

        this.sidebarCollapsed = this.navService.sidebarCollapsed.getValue();
        this.subscriptions.add(
            this.navService.sidebarCollapsed.subscribe((sidebarCollapsed: boolean) => {
                this.sidebarCollapsed = sidebarCollapsed;
            }),
        );

        this.contextApi = {
            myProfilePath: this.myProfilePath,
            sidebarCollapsed: this.sidebarCollapsed,
            toggleSidebarCollapse: this.toggleSidebarCollapse.bind(this),
            userId: this.userId,
            userImageUrl: this.userImageUrl,
            userName: this.userName,
        };
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setCurrentUserVariables(currentUser: ILoggedIn): void {
        this.userId = currentUser.Id;
        this.userName = currentUser.Name || 'User';
        this.userImageUrl = getProfileImgPathFromCurrentUser(currentUser, this.environmentService);
    }

    toggleSidebarCollapse(): void {
        this.navService.toggleSidebarCollapsed();
    }
}
