import { Injectable, Inject, Injector, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, pluck } from 'rxjs/operators';

import { EnvironmentService } from '@mt-ng2/environment-module';

import { Navbar } from './nav-header/nav-header.component';
import { AuthService } from '@mt-ng2/auth-module';
import { KeyboardShortcutHelpWindowService } from '@mt-ng2/keyboard-shortcuts-module';
import { NavModuleConfigToken, INavModuleConfig } from '@mt-ng2/nav-module-config';

export interface IState {
    navbar: Navbar;
}

const state: IState = {
    navbar: { sideBarCollapsed: false, showNav: true },
};

@Injectable()
export class NavService {
    public submitHelpUrl = (this.navModuleConfig && this.navModuleConfig.submitHelpUrl) || 'http://submit-help.rmm.milesapp.com';
    public isCustomSubmitHelpPage = (this.navModuleConfig && this.navModuleConfig.isCustomSubmitHelpPage) || false;
    public shouldShowSubmitHelpInNewTab = (this.navModuleConfig && this.navModuleConfig.shouldShowSubmitHelpInNewTab) || false;
    public myProfilePath = (this.navModuleConfig && this.navModuleConfig.myProfilePath) || 'users/my-profile';
    public logoUrl = (this.navModuleConfig && this.navModuleConfig.logoUrl) || `${this.environmentService.config.imgPath}logo-white.png`;
    public fullLogoUrl = (this.navModuleConfig && this.navModuleConfig.fullLogoUrl) || `${this.environmentService.config.imgPath}logo.png`;
    public appName = `${this.environmentService.config.appName}`;
    public sidebarCollapsed = new BehaviorSubject<boolean>(false);
    public showNav = new BehaviorSubject<boolean>(true);
    public showFooter = new BehaviorSubject<boolean>(true);
    public customHeaderHtml = new BehaviorSubject<string>(null);
    public customUserDetailsHtml = new BehaviorSubject<string>(null);
    public footerHtml = new BehaviorSubject<string>(null);
    public showSubmitHelpButton =
        this.navModuleConfig && typeof this.navModuleConfig.showSubmitHelpButton !== 'undefined' ? this.navModuleConfig.showSubmitHelpButton : true;
    public showNewTabButton =
        this.navModuleConfig && typeof this.navModuleConfig.showNewTabButton !== 'undefined' ? this.navModuleConfig.showNewTabButton : false;
    public hasKeyboardShortcutsModule =
        this.navModuleConfig && typeof this.navModuleConfig.hasKeyboardShortcutsModule !== 'undefined'
            ? this.navModuleConfig.hasKeyboardShortcutsModule
            : false;
    public showKeyboardShortcutsButton =
        this.navModuleConfig && typeof this.navModuleConfig.showKeyboardShortcutsButton !== 'undefined'
            ? this.navModuleConfig.showKeyboardShortcutsButton
            : this.hasKeyboardShortcutsModule;
    public siteName = this.navModuleConfig ? this.navModuleConfig.siteName : null;

    private subject = new BehaviorSubject<IState>(state);
    store = this.subject.asObservable().pipe(distinctUntilChanged());

    public keyboardShortcutHelpWindowService: KeyboardShortcutHelpWindowService;

    select<T>(name: string): Observable<T> {
        return this.store.pipe(pluck(name));
    }

    updateNav(navbar: Navbar): void {
        const value = this.subject.value;
        this.subject.next(value);
    }

    constructor(
        private http: HttpClient,
        private environmentService: EnvironmentService,
        private authService: AuthService,
        @Inject(NavModuleConfigToken) @Optional() protected navModuleConfig: INavModuleConfig,
        private injector: Injector,
    ) {
        if (this.hasKeyboardShortcutsModule) {
            this.keyboardShortcutHelpWindowService = this.injector.get(KeyboardShortcutHelpWindowService);
        }
        // setting footer html to initial value
        const footerHtml = navModuleConfig && navModuleConfig.footerHtml ? navModuleConfig.footerHtml : 'useDefaultMilesFooter';
        this.footerHtml.next(footerHtml);
    }

    setSidebarCollapsed(value: boolean): void {
        this.sidebarCollapsed.next(value);
    }

    toggleSidebarCollapsed(): void {
        this.sidebarCollapsed.next(!this.sidebarCollapsed.getValue());
    }

    setShowNav(value: boolean): void {
        this.showNav.next(value);
    }

    submitHelpRequest(): Observable<string> {
        const currentUser = this.authService.currentUser.getValue();
        const data = {
            appName: this.appName,
            url: window.location.href,
            userEmail: currentUser.Email || 'info@4miles.com',
            userName: currentUser.Name,
        };
        return this.http.post<string>(`${this.submitHelpUrl}/api/v1/submit-help/submit-request`, data);
    }
}
