import { Component, OnInit, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { SearchParams, ExtraSearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { NotificationsService } from '@mt-ng2/notifications-module';

import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IItemSelectedEvent, IItemDeletedEvent, IColumnSortedEvent, SortDirection, EntityListConfig } from '@mt-ng2/entity-list-module';
import { IEntity, MetaItemListDefinition, IIsMetaItemService } from '@mt-ng2/base-service';
import { IIsSharedEntities, ISharedEntitiesComponentConfig } from '../interfaces/shared-entities';
import { BlankSharedEntitiesConfig } from '../classes/shared-entities.library';
import { IEntityRouteConfig } from '@mt-ng2/entity-components-base';

@Component({
    selector: 'shared-entities-list',
    template: `
        <div>
            <div>
                <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
                <mt-search-filter-select
                    *ngFor="let filter of filters"
                    [items]="filter.FilterItems"
                    [entity]="filter.Name"
                    (onSelectionChanged)="filtersChanged()"
                ></mt-search-filter-select>
                <br *ngIf="!(filters && filters.length && filters.length > 0)" />
            </div>
            <entity-list
                [entities]="entitiesArray"
                [itemsPerPage]="itemsPerPage"
                [total]="total"
                [(currentPage)]="currentPage"
                (onPageChanged)="getEntities()"
                (onItemSelected)="selectEntity($event)"
                (onItemDeleted)="deleteEntity($event)"
                (onColumnSorted)="columnSorted($event)"
                [entityListConfig]="entityListConfig"
            >
            </entity-list>
            <a [routerLink]="['../']" class="btn btn-default">Back</a>
            <div *ngIf="canEdit" class="fab-wrap-b-r" (click)="addEntity()">
                <a class="btn btn-primary btn-fab-lg">
                    <span class="fa fa-plus"></span>
                </a>
            </div>
        </div>
    `,
})
export class SharedEntitiesListComponent implements OnInit {
    private _entitiesArray: IEntity[] = [];
    get entitiesArray(): IEntity[] {
        return this._entitiesArray;
    }

    private _canEdit: boolean;
    set canEdit(value: boolean) {
        this._canEdit = value;
    }
    get canEdit(): boolean {
        return this._canEdit;
    }

    private _service: IIsSharedEntities<IEntity>;
    public get service(): IIsSharedEntities<IEntity> {
        if (this._service && this.id && this.id > 0) {
            return this._service;
        }
        return null;
    }
    public set service(value: IIsSharedEntities<IEntity>) {
        this._service = value;
    }

    id: number;
    itemsPerPage = 12;
    currentPage = 1;
    total: number;
    query = '';
    order = '';
    orderDirection = '';
    filters: MetaItemListDefinition[] = [];

    entityListConfig: EntityListConfig = new EntityListConfig({
        columns: null,
    });

    _sharedEntitiesConfig: ISharedEntitiesComponentConfig<IEntity> = new BlankSharedEntitiesConfig();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private injector: Injector,
        private notificationsService: NotificationsService,
        private claimsService: ClaimsService,
    ) {}

    ngOnInit(): void {
        const config: IEntityRouteConfig = <IEntityRouteConfig>this.route.parent.snapshot.data;
        this.id = +this.route.parent.snapshot.paramMap.get(config.entityIdParam);
        const sharedEntity = (<any>config).sharedEntities.find((item) => item.path === this.route.snapshot.url[0].path);
        this.canEdit =
            this.claimsService.hasClaim(config.claimType, [ClaimValues.FullAccess]) ||
            (sharedEntity.canEditSubClaimType && this.claimsService.hasClaim(sharedEntity.canEditSubClaimType, [ClaimValues.FullAccess]));
        this.service = this.injector.get(sharedEntity.service);
        this._sharedEntitiesConfig = new sharedEntity.config();
        this.entityListConfig = this._sharedEntitiesConfig.SharedEntityListConfig.EntityListConfig;
        this.order = this.entityListConfig.getDefaultSortProperty();
        this.orderDirection = this.entityListConfig.getDefaultSortDirection();
        let filterServices = this._sharedEntitiesConfig.SharedEntityListConfig.FilterServices;
        for (let fs of filterServices) {
            let filterService = <IIsMetaItemService>this.injector.get(fs);
            filterService.MetaItemListServiceInitilizer.getItems().subscribe((answer) => {
                this.filters.push(answer);
            });
        }
        this.itemsPerPage = this._sharedEntitiesConfig.EntityListItemsPerPage;
        this.getEntities();
    }

    search(query: string): void {
        this.query = query;
        this.currentPage = 1;
        this.getEntities();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getEntities();
    }

    filtersChanged(): void {
        this.currentPage = 1;
        this.getEntities();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private getExtraSearchParams(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];

        for (let filter of this.filters) {
            _extraSearchParams.push(filter.toExtraSearchParams());
        }

        return _extraSearchParams;
    }

    getEntities(): IEntity[] {
        let search = this.query;
        const extraSearchParams: ExtraSearchParams[] = this.getExtraSearchParams();
        const searchEntity: IEntitySearchParams = {
            extraParams: extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search?.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };
        const searchparams = new SearchParams(searchEntity);

        this.service.getEntities(this.id, searchparams).subscribe((response) => {
            this._entitiesArray = response.body;
            this.total = +response.headers.get('X-List-Count');
        });
        return this._entitiesArray;
    }

    deleteEntity(event: IItemDeletedEvent): void {
        this.deleteEntityById(event.entity.Id);
    }

    deleteEntityById(entityId: number): void {
        this.service.deleteEntity(this.id, entityId).subscribe((answer) => {
            this.notificationsService.success(`${this._sharedEntitiesConfig.EntityName} Deleted Successfully`);
            this.getEntities();
        });
    }

    selectEntity(event: IItemSelectedEvent): void {
        this.router.navigate([`${event.entity.Id}`], { relativeTo: this.route });
    }

    addEntity(): void {
        this.router.navigate(['0'], { relativeTo: this.route });
    }
}
