import { FormGroup, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { EventEmitter, ChangeDetectorRef, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, Injector, Directive } from '@angular/core';
import { Subscription } from 'rxjs';

import { CustomErrorMessageHandler } from '@mt-ng2/dynamic-form-config';

import { DynamicField } from '../libraries/dynamic-field.library';
import { DynamicFormModuleConfig } from '../libraries/dynamic-form-module.config';
import { IFormElementEvents } from './form-elements';
import { CustomFormValidation } from './custom-form-validation.library';

@Directive()
export abstract class CustomFormControlComponentBase implements OnInit, AfterViewInit, OnDestroy, IFormElementEvents {
    /**
     * @property {DynamicField} instance of the dynamic field class that this control represents
     */
    config: DynamicField;
    /**
     * @property {FormGroup} form group that control is being attached to
     */
    parentGroup: FormGroup;
    /**
     * @property {CustomFormControlComponentBase} this so can be passed around
     */
    thisControl: CustomFormControlComponentBase = this;

    /**
     * @property {EventEmitter<any>} event that emits on blur, used by dynamic field directive to allow (blur) event binding
     */
    blur = new EventEmitter<any>();
    /**
     * @property {EventEmitter<any>} event that emits on focus, used by dynamic field directive to allow (focus) event binding
     */
    focus = new EventEmitter<any>();
    /**
     * @property {EventEmitter<any>} event that emits on valueChanges, used by dynamic field directive to allow (valueChanges) event binding
     */
    valueChanges = new EventEmitter<string>();

    /**
     * @property {Subscription} subscription object used to collect subscriptions for easy unsubscribing later
     */
    subscriptions = new Subscription();

    protected _isFocused = false;
    /**
     * @readonly
     * @property {boolean} allows view to read if the control is currently focused
     */
    get isFocused(): boolean {
        return this._isFocused;
    }
    protected _isDisabled = false;
    /**
     * @readonly
     * @property {boolean} allows view to read if the control is currently disabled
     */
    get isDisabled(): boolean {
        return this._isDisabled;
    }

    protected _autoFocus: boolean;

    protected _autoCompleteEnabled: boolean;

    /**
     * @property {ElementRef} finds the element in the view that has the tag #inputElement and returns it as an ElementRef.  This is used to perform operations like .nativeElement.focus()
     */
    @ViewChild('inputElement', { static: false }) inputElement: ElementRef;

    public changeDetectorRef: ChangeDetectorRef;
    /**
     * mark as touched should happen on blur, but our old dynamic forms did it on focus
     * so we allow for both depending on the configuration
     */
    public markAsTouchedOnFocus = false;
    public moduleErrorMessageHandler: CustomErrorMessageHandler;

    constructor(changeDetectorRefOrInjector: ChangeDetectorRef | Injector) {
        if ((changeDetectorRefOrInjector as ChangeDetectorRef).detectChanges) {
            this.changeDetectorRef = changeDetectorRefOrInjector as ChangeDetectorRef;
        } else {
            this.changeDetectorRef = (changeDetectorRefOrInjector as Injector).get(ChangeDetectorRef);
            const moduleConfig = (changeDetectorRefOrInjector as Injector).get(DynamicFormModuleConfig);
            this.markAsTouchedOnFocus = moduleConfig.markAsTouchedOnFocus ? true : false;
            this.moduleErrorMessageHandler = moduleConfig.errorMessageHandler;
        }
    }

    /**
     * @description handles setup work such as subscribing to mt control events and setting initial values
     */
    ngOnInit(): void {
        this.subscriptions.add(
            this.getControl().mtExternalFocusEvent.subscribe(() => {
                this.focusMe();
            }),
        );

        this.subscriptions.add(
            this.getControl().mtSetRequiredEvent.subscribe((required: boolean) => {
                this.setRequired(required);
            }),
        );

        this.subscriptions.add(
            this.getControl().statusChanges.subscribe((status) => {
                this._isDisabled = status === 'DISABLED';
            }),
        );

        this._autoFocus = this.config.autoFocus;
        this._autoCompleteEnabled = this.config.autoCompleteEnabled;
        this._isDisabled = this.getControl().status === 'DISABLED';
    }

    /**
     * @description handles teardown work such as unsubscribing
     */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    /**
     * @description handles a few items that must be done after view init such as subscribing to valueChanges and handling autoFocus
     */
    ngAfterViewInit(): void {
        this.subscriptions.add(
            this.getControl().valueChanges.subscribe((value) => {
                this.valueChanges.emit(value);
            }),
        );

        if (this._autoFocus) {
            this._autoFocus = false;
            setTimeout(() => {
                this.focusMe();
                this.changeDetectorRef.detectChanges();
            });
        }
    }

    /**
     * @description attempts to set focus to the inputElement.  This is called whenever the mtFocus() event is fired
     */
    focusMe(): void {
        this.inputElement?.nativeElement?.focus?.();
    }

    /**
     * @description event that handles an onBlur event from the inputElement.  Must be wired up manually using the input's event like this (blur)="onBlur()"
     */
    onBlur(): void {
        if (this.getControl().enabled) {
            this._isFocused = false;
            if (!this.markAsTouchedOnFocus) {
                this.getControl().markAsTouched();
            }
            this.blur.emit(true);
        }
    }

    /**
     * @description event that handles an onFocus event from the inputElement.  Must be wired up manually using the input's event like this (focus)="onFocus()"
     */
    onFocus(): void {
        if (this.getControl().enabled) {
            this._isFocused = true;
            if (this.markAsTouchedOnFocus) {
                this.getControl().markAsTouched();
            }
            this.focus.emit();
        }
    }

    /**
     * @description gets the instance of the AbstractControl from the form
     * @returns {AbstractControl}
     */
    getControl(): AbstractControl {
        return this.getControlFromFormGroup(this.parentGroup, this.config);
    }

    private getControlFromFormGroup(group: FormGroup, config: DynamicField): AbstractControl {
        let control = group.get([config.formGroup, config.name]);
        if (!control) {
            control = group.get(config.name);
        }
        return control;
    }

    /**
     * @description gets the instance of the FromGroup this control is attached to
     * @returns {FormGroup}
     */
    getGroup(): FormGroup {
        return this.getGroupFromConfig(this.parentGroup, this.config);
    }

    private getGroupFromConfig(parentGroup: FormGroup, config: DynamicField): FormGroup {
        let group: FormGroup = config.formGroup ? <FormGroup>parentGroup.get([config.formGroup]) : parentGroup;
        return group;
    }

    /**
     * @description attempts to set the required validator.  This is called whenever the mtSetRequired() event is fired
     */
    setRequired(required: boolean): void {
        this.config.setRequired(required);
        this.getControl().setValidators(this.config.validation.map((v) => <ValidatorFn>v));
        this.getControl().updateValueAndValidity({
            emitEvent: false,
            onlySelf: true,
        });
    }

    /**
     * @description gets the maxLength from the validators object.  If not set, then returns ''
     * @returns {string}
     */
    getMaxLength(): string {
        return this.config?.validators?.maxlength?.toString() ?? '';
    }

    /**
     * @description gets the maxLengthValue from the validators object.  If not set, then returns 0
     * @returns {number}
     */
    getMaxLengthValue(): number {
        return this.config?.validators?.maxlength ?? 0;
    }

    getMaxLengthText(): string {
        const control = this.getControl();
        return `${control?.value?.length ?? 0}/${this.config.validators.maxlength}`;
    }

    /**
     * @description determines if maxLength value is set
     * @returns {boolean}
     */
    hasMaxLength(): boolean {
        return this.config?.validators?.maxlength ? true : false;
    }

    /**
     * @description is the formcontrol required, used to determine if should show required '*' in label
     * @returns {boolean}
     */
    showRequired(): boolean {
        return (this.getControl().validator && this.getControl().validator({} as AbstractControl)?.required) || null;
    }

    /**
     * @description is the formcontrol not required, used to determine if should show 'optional' in label
     * @returns {boolean}
     */
    showOptional(): boolean {
        return !this.config?.validators?.required && this.config?.validators?.showOptional;
    }

    /**
     * @description is the formcontrol having an error (specific or generic) and has been touched
     * @param {string} error optional value that scopes the hasError check to a specific error if set
     * @returns {boolean}
     */
    hasError(error?: string): boolean {
        const control = this.getControl();
        if (error) {
            return control.hasError(error) && (error === 'maxlength' || control.touched);
        } else {
            return control.errors && (control.touched || control.errors.maxlength);
        }
    }

    get errorMessage(): string {
        const control = this.getControl();
        const errors = control?.errors;
        if (!(errors && control?.touched)) {
            return '';
        }
        const message = Object.keys(errors)
            .map((key) => this.getErrorMessageFromKey(key, errors))
            .filter((message) => message)
            .join(', ');

        return message;
    }

    protected getErrorMessageFromKey(key: string, errors: ValidationErrors): string {
        // check config then module for custom error message handler
        const customErrorMessageHandler = this.config.errorMessageHandler ?? this.moduleErrorMessageHandler;
        return CustomFormValidation.getErrorMessageFromKey(key, errors, this.config, customErrorMessageHandler);
    }
}
