import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnChanges,
    SimpleChanges,
    OnDestroy,
    Inject,
    ViewChild,
    Optional,
    forwardRef,
} from '@angular/core';
import { FormGroup, FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs';

import { NgbDateStruct, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, InputTypes } from '@mt-ng2/dynamic-form';
import {
    MtSearchFilterDateRangeModuleConfigToken,
    IContextualDate,
    ISearchFilterDaterangeValue,
    DaysOfTheWeek,
    IMtSearchFilterDaterangeModuleConfig,
} from '@mt-ng2/search-filter-daterange-control-config';

import { defaultSearchFilterModuleConfig } from './default-module-config';

@Component({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MtSearchFilterDaterangeComponent),
        },
    ],
    selector: 'mt-search-filter-daterange',
    styles: [
        `
            .show.dropdown {
                display: inline !important;
            }
            .form-padding {
                padding: 10px;
                width: 400px;
            }
            .start-date {
                float: left;
            }
            .end-date {
                float: right;
            }
            .action-div {
                min-height: 30px;
            }
            .btn-clear {
                margin-right: 5px;
            }
            #start-after-end-error {
                position: absolute;
                bottom: 0;
                left: 10px;
            }
            .dropdown-menu{
                bottom: initial;
                margin-bottom: initial;
            }
        `,
    ],
    templateUrl: './mt-search-filter-daterange.component.html',
})
export class MtSearchFilterDaterangeComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
    // *** CONTROL VALUE ACCESSOR ***
    /* tslint:disable:member-ordering */
    writeValue(obj: any): void {
        this.handleWriteValue(obj);
    }
    protected onValueChanged: (value: any) => void;
    registerOnChange(fn: any): void {
        this.onValueChanged = fn;
    }
    protected onTouched: () => void;
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        // currently does not handle disabled state
    }
    // *** END -- CONTROL VALUE ACCESSOR ***

    @Input() startDate: Date = null;
    @Input() endDate: Date = null;
    @Input() minDate: Date = null;
    @Input() maxDate: Date = null;
    @Input() contextualDateType: IContextualDate;
    @Input() availableContextualDates: IContextualDate[];
    @Input() throughText: string;
    @Input() entity: string;
    @Input() showWeekNumbers: boolean = null;

    @Output('onSelectionChanged') selectionChanged: EventEmitter<ISearchFilterDaterangeValue> = new EventEmitter<ISearchFilterDaterangeValue>();
    @ViewChild('ngbDropdown', { static: false }) ngbDropdown: NgbDropdown;

    firstDayOfTheWeek: DaysOfTheWeek;
    daterangeForm: FormGroup;
    startDateField: DynamicField;
    endDateField: DynamicField;
    contextualDateTypes: IContextualDate[];
    subscriptions: Subscription = new Subscription();

    constructor(
        @Inject(MtSearchFilterDateRangeModuleConfigToken)
        @Optional()
        private searchFilterModuleConfig: IMtSearchFilterDaterangeModuleConfig,
    ) {}

    ngOnInit(): void {
        this.searchFilterModuleConfig = Object.assign(defaultSearchFilterModuleConfig, this.searchFilterModuleConfig);
        this.firstDayOfTheWeek = this.searchFilterModuleConfig.firstDayOfWeek;
        this.daterangeForm = new FormGroup({
            ContextualDateType: new FormControl(this.contextualDateType),
        });
        this.daterangeForm.setValidators(this.validateDateIsNotReversed.bind(this));
        this.setDynamicFields();
        this.subscribeToContextualDateTypeChange();

        this.SetContextualDateRanges();
    }

    private SetContextualDateRanges(): void {
        if (this.availableContextualDates?.length > 0) {
            this.contextualDateTypes = this.availableContextualDates;
        } else {
            this.contextualDateTypes = this.searchFilterModuleConfig.contextualDates;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.daterangeForm && (changes.startDate || changes.endDate)) {
            let changeWasApplied = false;
            if (changes.startDate && this.daterangeForm.value.start !== changes.startDate.currentValue) {
                this.daterangeForm.get('start').setValue(changes.startDate.currentValue);
                changeWasApplied = true;
            }
            if (changes.endDate && this.daterangeForm.value.end !== changes.endDate.currentValue) {
                this.daterangeForm.get('end').setValue(changes.endDate.currentValue);
                changeWasApplied = true;
            }
            if (changeWasApplied) {
                this.emitChange();
            }
        }
    }

    setDynamicFields(): void {
        if (this.contextualDateType) {
            let dateRange = this.contextualDateType.GetDates(this.firstDayOfTheWeek);
            this.startDate = dateRange.startDate;
            this.endDate = dateRange.endDate;
        }
        this.startDateField = this.getDynamicFieldDate('start', this.startDate);
        this.endDateField = this.getDynamicFieldDate('end', this.endDate);
    }

    getDynamicFieldDate(name: string, value: Date): DynamicField {
        let minDateAsNgbDateStruct = this.minDate
            ? ({
                  day: this.minDate.getDate(),
                  month: this.minDate.getMonth() + 1,
                  year: this.minDate.getFullYear(),
              } as NgbDateStruct)
            : null;
        let maxDateAsNgbDateStruct = this.maxDate
            ? ({
                  day: this.maxDate.getDate(),
                  month: this.maxDate.getMonth() + 1,
                  year: this.maxDate.getFullYear(),
              } as NgbDateStruct)
            : null;
        const field = new DynamicField({
            formGroup: null,
            label: name,
            name: name,
            type: new DynamicFieldType({
                datepickerOptions: {
                    firstDayOfTheWeek: this.firstDayOfTheWeek,
                    maxDate: maxDateAsNgbDateStruct,
                    minDate: minDateAsNgbDateStruct,
                    showWeekNumbers: this.showWeekNumbers || this.searchFilterModuleConfig.showWeekNumbers,
                },
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Datepicker,
            }),
            value: value,
        });
        field.type.datepickerOptions.showClearButton = false;
        return field;
    }

    validateDateIsNotReversed(): any {
        let form = this.daterangeForm;
        if (!form) {
            return null;
        }
        const startDateControl = form.get('start');
        const endDateControl = form.get('end');
        if (!startDateControl || !endDateControl) {
            return null;
        }
        let startDate = startDateControl.value;
        let endDate = endDateControl.value;
        if (startDate && endDate && startDate > endDate) {
            return {
                dateIsReversed: true,
            };
        }
        return null;
    }

    hasDatesReversedError(): boolean {
        return this.daterangeForm?.errors?.dateIsReversed ?? false;
    }

    selectedItemsText(): string {
        if (!this.startDate && !this.endDate) {
            return `Any ${this.entity}`;
        }
        if (!this.startDate) {
            return `${this.entity}: <small>${this.searchFilterModuleConfig.beforeText} ${this.getDateString(this.endDate)}</small>`;
        }
        if (!this.endDate) {
            return `${this.entity}: <small>${this.searchFilterModuleConfig.afterText} ${this.getDateString(this.startDate)}</small>`;
        }
        return `${this.entity}: <small>${this.getDateString(this.startDate)} ${
            this.throughText || this.searchFilterModuleConfig.throughText
        } ${this.getDateString(this.endDate)}</small>`;
    }

    getDateString(date: Date): string {
        // Fix for typescript compiler error https://stackoverflow.com/a/66590756/5997923
        const options = { year: 'numeric', month: 'short', day: 'numeric' } as const;
        return date.toLocaleDateString('en-US', options);
    }

    applyChanges(): void {
        if (this.daterangeForm.valid) {
            this.startDate = this.daterangeForm.value.start;
            this.endDate = this.daterangeForm.value.end;
        }
        this.emitChange();
        this.closeDropdown();
    }

    closeDropdown(): void {
        this.ngbDropdown?.close?.();
    }

    emitChange(): void {
        const value: ISearchFilterDaterangeValue = {
            endDate: this.endDate,
            startDate: this.startDate,
        };
        this.selectionChanged.emit(value);
        this.onValueChanged?.(value);
        // this onTouched could/should be called much earlier, but really this
        // control will not often be used in a context where when touched will
        // matter, so not going to add more to this component to move it to an
        // earlier spot (i.e. when the dropdown opens, etc.)
        this.onTouched?.();
    }

    clearValues(): void {
        this.daterangeForm.reset();
        this.applyChanges();
    }

    subscribeToContextualDateTypeChange(): void {
        this.subscriptions.add(
            this.daterangeForm.get('ContextualDateType').valueChanges.subscribe((value: IContextualDate) => {
                if (!value) {
                    return;
                }
                let dateRange: ISearchFilterDaterangeValue = value.GetDates(this.firstDayOfTheWeek);
                this.assignDates(dateRange);
            }),
        );
    }

    protected handleWriteValue(value: ISearchFilterDaterangeValue): void {
        this.startDate = value?.startDate ?? null;
        this.endDate = value?.endDate ?? null;
        if (this.daterangeForm.get('start')) {
            this.daterangeForm.get('start').setValue(this.startDate);
            this.daterangeForm.get('end').setValue(this.endDate);
        } else {
            this.startDateField.value = this.startDate;
            this.endDateField.value = this.endDate;
        }
    }

    assignDates(dateRange: ISearchFilterDaterangeValue): void {
        this.daterangeForm.get('start').setValue(dateRange.startDate);
        this.daterangeForm.get('end').setValue(dateRange.endDate);
        this.applyChanges();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
    // possible refactor: clear out contextual date when the user selects a date that's not one of the filters
    // clearOutContextualDate(): void {
    //     this.daterangeForm.get('ContextualDateType').setValue(null);
    // }
}
