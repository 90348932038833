export enum RestrictIntervalMode {
    FLOOR,
    ROUND,
    CEIL,
}

export interface ITimepickerOptions {
    meridian?: boolean;
    restrictIntervalMode?: RestrictIntervalMode;
    restrictMinuteInterval?: number;
    seconds?: boolean;
    showClearButton?: boolean;
    spinners?: boolean;
}
