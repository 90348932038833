import { AuthService } from './../services/auth.service';
import { map } from 'rxjs/operators';
import { TokenService } from './../services/token.service';
import { AuthConfig } from './../libraries/auth-config.library';
import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class MtTwoFactorGuard implements CanActivate {
    protected authConfig: AuthConfig;
    protected tokenService: TokenService;
    protected authService: AuthService;
    protected router: Router;

    constructor(injector: Injector) {
        this.authConfig = injector.get(AuthConfig);
        this.router = injector.get(Router);
        this.tokenService = injector.get(TokenService);
        this.authService = injector.get(AuthService);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.tokenService.get2faInfoFromCookie().pipe(
            map((info) => {
                if (!info) {
                    return false;
                }
                this.authService.appReady.next(true);
                // return this.router.url.startsWith(this.authConfig.paths.twoFactorVerifyPath);
                return `/${route.url[0].path}` === this.authConfig.paths.twoFactorVerifyPath;
            }),
        );
    }
}
