import { Component, Injector, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';

import { AuthService, MtAuthGuard } from '@mt-ng2/auth-module';
import { NotificationsService } from '@mt-ng2/notifications-module';

import { LoginConfig } from '../../../libraries/login-config.library';

@Component({
    selector: 'app-ms-login',
    styleUrls: ['./ms-login.component.less'],
    templateUrl: './ms-login.component.html',
})
export class MsLoginComponent implements OnInit {
    @Input() config: LoginConfig;
    protected microsoftService: MsalService;
    protected authService: AuthService;
    protected router: Router;
    protected route: ActivatedRoute;
    protected notificationService: NotificationsService;
    returnUrl: string;

    constructor(injector: Injector) {
        this.authService = injector.get(AuthService);
        this.router = injector.get(Router);
        this.route = injector.get(ActivatedRoute);
        this.microsoftService = injector.get(MsalService);
        this.notificationService = injector.get(NotificationsService);
    }

    ngOnInit(): void {
        this.returnUrl = this.route.snapshot.queryParams[MtAuthGuard.Return_Url_QueryParam];
    }

    microsoftSignIn(): void {
        this.microsoftService
            .loginPopup({ scopes: this.config.microsoftAuthConfig.additionalScopes })
            .subscribe(this.handleMicrosoftSignIn.bind(this));
    }

    handleMicrosoftSignIn(authResponse: AuthenticationResult): void {
        this.authService.microsoftLogin(authResponse).subscribe(
            (resp) => {
                if (this.returnUrl) {
                    this.router.navigateByUrl(this.returnUrl);
                } else {
                    this.router.navigate(['/home']);
                }
            },
            (errorResponse) => {
                if (errorResponse.status === 418) {
                    this.notificationService.error(this.config.messageOverrides.microsoftSignInFailureEmail);
                } else {
                    this.notificationService.error(this.config.messageOverrides.microsoftSignInFailure);
                }
            },
        );
    }
}
