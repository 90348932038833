import { Injectable, Inject, forwardRef } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';

import { IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { SearchParams } from '@mt-ng2/common-classes';
import { BadRequestError, NotFoundError, AppError } from '@mt-ng2/errors-module';

import { ISharedEntitiesComponentConfig, IIsSharedEntities, ISharedEntityListConfig } from '../interfaces/shared-entities';
import { IAdditionalSharedEntityConfig } from '../interfaces/additional-shared-entities';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IEntity } from '@mt-ng2/entity-list-module';
import { IMetaItemListDefinition } from '@mt-ng2/base-service';
import { ActivatedRoute } from '@angular/router';
import { ICloseButtonParams } from '../interfaces/close-button-params';

export enum SharedEntitiesEditOptions {
    InPlace,
    InPlaceWithAdditionalInfoButton,
    InSeparatePage,
}

export enum AdditionalSharedEntityTypes {
    Phone,
    Address,
    Custom,
}

export class SharedEntitiesComponentConfig<T extends IEntity> {
    protected metaItemLists: IMetaItemListDefinition[] = [];
    public MetaItemServices: any[] = [];
    AdditionalSharedEntities = [];
    SharedEntitiesEditOption = SharedEntitiesEditOptions.InPlace;

    constructor(
        public EmptyEntity: T,
        public EntityName: string,
        public EntityNamePluralized: string,
        public SharedEntityListConfig: ISharedEntityListConfig,
        public EntityListItemsPerPage: number,
    ) {}

    setMetaItemLists(lists: IMetaItemListDefinition[]): void {
        this.metaItemLists = lists;
    }

    protected getMetaItemValues(serviceName: string): any[] {
        let list = this.metaItemLists.find((item) => item.ServiceName === serviceName);
        return list.Items;
    }
}

export class BlankSharedEntitiesConfig extends SharedEntitiesComponentConfig<IEntity> implements ISharedEntitiesComponentConfig<IEntity> {
    AdditionalSharedEntities = [];
    SharedEntitiesEditOption = SharedEntitiesEditOptions.InPlace;

    constructor() {
        super({ Id: 0 }, '', '', { FilterServices: [], EntityListConfig: null }, 12);
    }
    getFormValues(entity: IEntity, formValue: IEntity): IEntity {
        return { ...this.EmptyEntity };
    }
    getDynamicFormConfig(entity: IEntity): IDynamicFormConfig {
        return { formObject: [], viewOnly: [] };
    }
    getRow = (entity: IEntity) => '';
}

export class SharedEntitiesService<T extends IEntity> implements IIsSharedEntities<T> {
    constructor(private baseurl: string, private entityurl: string, @Inject(forwardRef(() => HttpClient)) protected http: HttpClient) {}

    getEntities(parentId: number, searchparameters: SearchParams): Observable<HttpResponse<T[]>> {
        let params = this.getHttpParams(searchparameters);
        return this.http
            .get<T[]>(`${this.baseurl}/${parentId}${this.entityurl}/_search`, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError(this.handleError));
    }

    getEntity(parentId: number, entityId: number): Observable<T> {
        return this.http.get<T>(`${this.baseurl}/${parentId}${this.entityurl}/${entityId}`).pipe(catchError(this.handleError));
    }

    saveEntity(parentId: number, entity: T): Observable<number> {
        if (!entity.Id) {
            entity.Id = 0;
            return this.http.post<number>(`${this.baseurl}/${parentId}${this.entityurl}`, entity);
        } else {
            return this.http.put<number>(`${this.baseurl}/${parentId}${this.entityurl}/${entity.Id}`, entity, { responseType: 'text' as 'json' });
        }
    }

    getCloseButtonRoute(route: ActivatedRoute): ICloseButtonParams {
        return { commands: ['../'], extras: { relativeTo: route } };
    }

    deleteEntity(parentId: number, entityId: number): Observable<object> {
        return this.http.delete(`${this.baseurl}/${parentId}${this.entityurl}/${entityId}`, { responseType: 'text' as 'json' });
    }

    protected handleError(error: Response): Observable<any> {
        if (error.status === 400) {
            return throwError(new BadRequestError(error.json()));
        }

        if (error.status === 404) {
            return throwError(new NotFoundError());
        }

        return throwError(new AppError(error));
    }

    protected getHttpParams(searchparameters: SearchParams): HttpParams {
        let params = new HttpParams();
        if (searchparameters.query) {
            params = params.append('query', searchparameters.query);
        }
        if (searchparameters.skip) {
            params = params.append('skip', searchparameters.skip.toString());
        }
        if (searchparameters.take) {
            params = params.append('take', searchparameters.take.toString());
        }
        if (searchparameters.order) {
            params = params.append('order', searchparameters.order.toString());
        }
        if (searchparameters.orderDirection) {
            params = params.append('orderDirection', searchparameters.orderDirection.toString());
        }
        if (searchparameters.extraParams?.length > 0) {
            let extraparams = new HttpParams();
            searchparameters.extraParams.forEach((param) => {
                if (param.valueArray) {
                    if (param.valueArray.length > 0) {
                        extraparams = extraparams.append(param.name, param.valueArray.toString());
                    }
                } else {
                    if (param.value.length > 0) {
                        extraparams = extraparams.set(param.name, param.value);
                    }
                }
            });
            if (extraparams.keys().length > 0) {
                params = params.append('extraparams', extraparams.toString());
            }
        }
        return params;
    }
}

export class AdditionalSharedEntityConfig<T, K> implements IAdditionalSharedEntityConfig {
    constructor(
        public type: AdditionalSharedEntityTypes,
        public accessorFunction: (entity: T) => K[],
        public saveMethodName: string,
        public deleteMethodName: string,
        public componentName: string = null,
        public max = 0,
        public useMaxAsLimit = false,
    ) {
        if (!this.componentName) {
            this.componentName = this.getDefaultComponentName();
        }
    }

    private getDefaultComponentName(): string {
        switch (this.type) {
            case AdditionalSharedEntityTypes.Address:
                return 'Address';
            case AdditionalSharedEntityTypes.Phone:
                return 'Phone';
            default:
                return '';
        }
    }
}
