import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';
import { ILoggedIn } from '../models/auth-service.models';

/**
 * Object for defining the role access types. If
 * neither are present then it is assumed that you
 * have no access
 * @property FullAccess
 * @property ReadOnly
 */
export enum ClaimValues {
    FullAccess = 1,
    ReadOnly = 2,
}

@Injectable()
export class ClaimsService {
    private currentUserClaimValues: any = {};

    constructor(private authService: AuthService) {
        this.setClaims(this.authService.currentUser.getValue());
        this.authService.currentUser.subscribe((currentUser) => this.setClaims(currentUser));
    }

    private setClaims(currentUser: ILoggedIn): void {
        this.currentUserClaimValues = currentUser.ClaimValues;
    }

    /**
     * Ichecks the current users claims based on type and looks for match within acceptedValues
     * @param type
     * @param acceptedValues
     */
    hasClaim(type: number, acceptedValues: ClaimValues[]): boolean {
        const claim: number = this.currentUserClaimValues[type];
        return claim && acceptedValues.indexOf(claim) >= 0;
    }
}
