import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MtSearchFilterDaterangeComponent } from './mt-search-filter-daterange.component';
import { DynamicFormModule } from '@mt-ng2/dynamic-form';

@NgModule({
    declarations: [MtSearchFilterDaterangeComponent],
    exports: [MtSearchFilterDaterangeComponent],
    imports: [CommonModule, DynamicFormModule, NgbModule],
})
export class MtSearchFilterDaterangeModule {}
