import { Directive, OnInit, Input, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { NavContentDynamicHeaderComponent, NavContentDynamicRowComponent, NavSidebarHeaderItem, NavSidebarRowItem } from '@mt-ng2/nav-module-config';

@Directive({
    selector: '[navDynamicContent]',
})
export class NavDynamicContentDirective implements OnInit {
    @Input() componentRef: any;
    @Input() item: NavSidebarHeaderItem | NavSidebarRowItem;

    component;

    constructor(private resolver: ComponentFactoryResolver, private container: ViewContainerRef) {}

    ngOnInit(): void {
        const factory = this.resolver.resolveComponentFactory<any>(this.componentRef);
        this.component = this.container.createComponent(factory);
        if (typeof this.componentRef === typeof NavContentDynamicHeaderComponent) {
            (this.component.instance as NavContentDynamicHeaderComponent).header = this.item as NavSidebarHeaderItem;
        }
        if (typeof this.componentRef === typeof NavContentDynamicRowComponent) {
            (this.component.instance as NavContentDynamicRowComponent).row = this.item as NavSidebarRowItem;
        }
    }
}
