import { Injectable, Injector } from '@angular/core';

import { MtAuthGuard } from './mt-auth-guard.library';

@Injectable()
export class AuthGuard extends MtAuthGuard {
    constructor(injector: Injector) {
        super(injector);
    }
}
