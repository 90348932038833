import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import Quill, { Delta } from 'quill';

import { IWysiwygComponentConfig, IWysiwygComponentApi, buildQuillToolbarConfig } from '../libraries/wysiwyg.library';
import { ContentChange, QuillModules } from 'ngx-quill';

export interface IContentChangeEvent {
    html: string | null;
    text: string;
}

@Component({
    selector: 'mt-wysiwyg',
    templateUrl: './wysiwyg.component.html',
})
export class WysiwygComponent implements OnInit {
    // things got funky when dynamically loading the stylesheet
    // only on initial page load would some odd behavior happen
    // so using readyToShow with a setTimeout to ensure all stylesheets
    // are parsed prior to trying to show the control
    readyToShow = false;

    quillEditor: Quill;

    @Input() parentForm: FormGroup = null;
    @Input() controlName: string = null;
    @Input() disabled = false;
    @Input() config: IWysiwygComponentConfig = {
        toolbar: ['bold', 'italic', 'underline', 'link'],
    };

    private _value: string = null;
    @Input()
    set value(value: string) {
        this._value = value;
        this.valueChange.emit(value);
    }
    get value(): string {
        return this._value;
    }
    @Output() valueChange = new EventEmitter<string>();

    @Output() blur = new EventEmitter<null>();
    @Output() focus = new EventEmitter<null>();
    @Output() contentChanged = new EventEmitter<IContentChangeEvent>();

    @Input() insertImageOnPaste = true;

    get quillModuleConfig(): QuillModules {
        if (this.config?.toolbar) {
            return {
                backticks: {},
                blotFormatter: {
                    // empty object for default behaviour.
                },
                toolbar: buildQuillToolbarConfig(this.config.toolbar),
            };
        }
        return {
            blotFormatter: {
                // empty object for default behaviour.
            },
        };
    }

    @Output() ready = new EventEmitter<IWysiwygComponentApi>();

    editorCreated(editor: Quill): void {
        this.quillEditor = editor;
        this.quillEditor.clipboard.addMatcher('img', (node, delta) => {
            this.config.imagePasteHandler?.(delta.ops[0].insert.image);
            if (this.insertImageOnPaste) {
                return delta;
            }
            return null;
        });
    }

    ngOnInit(): void {
        this.ready.emit({
            focus: this.focusMe.bind(this),
        });

        // see explanation of the readyToShow property for why this is in a setTimeout here
        setTimeout(() => {
            this.readyToShow = true;
        }, 0);
    }

    focusMe(): void {
        if (this.quillEditor) {
            this.quillEditor.focus();
        }
    }

    handleContentChanged(event: ContentChange): void {
        const changeEvent: IContentChangeEvent = {
            html: event.html,
            text: event.text,
        };
        this.contentChanged.emit(changeEvent);
    }
}
