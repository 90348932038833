import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { throwError as observableThrowError, Observable, forkJoin } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { TokenService } from '../services/token.service';

@Injectable()
export class RefreshInterceptor implements HttpInterceptor {
    private authService: AuthService;
    private tokenService: TokenService;

    constructor(private injector: Injector) {}

    /**
     * looks for requests to refresh endpoint and logs out the user if a refresh fails.
     * also looks for token-expired or x-update-roles headers on non-refresh end points
     * that fail, and if found runs the tokenRefresh and retries these requests.
     * @param request
     * @param next
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.authService) {
            this.authService = this.injector.get(AuthService);
        }
        if (!this.tokenService) {
            this.tokenService = this.injector.get(TokenService);
        }
        // if this is a refresh request, then hook into it and logout if error
        if (request.url.endsWith('authUsers/refresh')) {
            return next.handle(request).pipe(
                catchError((error) => {
                    this.authService.logout(true);
                    return observableThrowError(error);
                }),
            );
        }

        // otherwise we catch errors with the header X-Update-Roles OR Expired-Token
        // and attempt to refresh and push response again
        return next.handle(request).pipe(
            catchError((error) => {
                const tokenRefreshNeeded =
                    error instanceof HttpErrorResponse &&
                    ((<HttpErrorResponse>error).headers.has('X-Update-Roles') || (<HttpErrorResponse>error).headers.has('token-expired'));
                if (tokenRefreshNeeded) {
                    return this.handleTokenRefresh(next, request);
                }
                return observableThrowError(error);
            }),
            finalize(() => {
                this.authService.appReady.next(true);
            }),
        );
    }

    /**
     * reissue the next.handle for a request after a successful refreshToken call has been completed
     * @param next
     * @param request
     */
    protected handleTokenRefresh(next: HttpHandler, request: HttpRequest<any>): Observable<HttpEvent<any>> {
        return this.authService.refreshToken().pipe(
            switchMap((loginToken) => {
                request = this.addAuthHeader(request, loginToken.token, loginToken.xsrf);
                return next.handle(request);
            }),
            catchError((innerError) => {
                // refresh request error hook above will logout the user
                return observableThrowError(innerError);
            }),
        );
    }

    /**
     * add the authentication headers from the new token returned from refreshToken
     * @param request
     * @param token
     * @param xsrfToken
     */
    protected addAuthHeader(request: HttpRequest<any>, token: string, xsrfToken: string): any {
        if (token) {
            return request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    'X-XSRF-TOKEN': xsrfToken,
                },
            });
        }
        return request;
    }
}
