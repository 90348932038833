import { Component, OnInit, AfterViewInit, Renderer2, NgZone, Input, Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthService, IGoogleLoginObject, MtAuthGuard } from '@mt-ng2/auth-module';

import { IGoogleProfile } from '../../libraries/google-sign-in.library';
import { LoginService } from '../../services/login.service';
import { LoginConfig } from '../../libraries/login-config.library';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const google: any;

@Component({
    selector: 'app-google-login',
    styleUrls: ['./google-login.component.less'],
    templateUrl: './google-login.component.html',
})
export class GoogleLoginComponent implements OnInit, AfterViewInit {
    @Input() config: LoginConfig;

    returnUrl: string;

    protected authService: AuthService;
    protected router: Router;
    protected notificationsService: NotificationsService;
    protected document: Document;
    protected ngZone: NgZone;
    protected loginService: LoginService;
    protected route: ActivatedRoute;

    constructor(injector: Injector, protected renderer2: Renderer2) {
        this.authService = injector.get(AuthService);
        this.router = injector.get(Router);
        this.notificationsService = injector.get(NotificationsService);
        this.document = injector.get(DOCUMENT);
        this.ngZone = injector.get(NgZone);
        this.loginService = injector.get(LoginService);
        this.route = injector.get(ActivatedRoute);
    }

    ngOnInit(): void {
        this.getGoogleScripts();
        this.returnUrl = this.route.snapshot.queryParams[MtAuthGuard.Return_Url_QueryParam];
    }

    getGoogleScripts(): void {
        if (!this.config.googleAuthConfig.googleApiKey) {
            throw 'googleAuthConfig is missing the googleApiKey';
        }
        //https://developers.google.com/identity/gsi/web/guides/migration#the_new_way
        // shoot google api script tag onto page
        const s = this.renderer2.createElement('script');
        s.src = 'https://accounts.google.com/gsi/client';
        s.async = true;
        s.defer = true;
        this.renderer2.appendChild(this.document.body, s);
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.setupGoogleSignIn(), 1000);
    }

    //https://developers.google.com/identity/gsi/web/reference/js-reference#google.accounts.id.initialize
    setupGoogleSignIn(): void {
        google.accounts.id.initialize({
            client_id: this.config.googleAuthConfig.googleApiKey,
            ux_mode: 'popup',
            callback: this.onGoogleSignIn.bind(this)
        });
        if(this.config.googleAuthConfig.autoPrompt) {
            google.accounts.id.prompt();
        }
        google.accounts.id.renderButton(document.getElementById('google-sign-in-btn'), {
            type: 'standard',
        });
    }

    onGoogleSignIn(credentialResponse: any): void {
        const idToken = credentialResponse.credential as string;

        const decoded = this.parseJwt(idToken);
        const googleProfile: IGoogleProfile = {
            email: decoded.email,
            familyName: decoded.family_name,
            givenName: decoded.given_name,
            id: decoded.sub,
            imageUrl: decoded.picture,
            name: decoded.name,
        };
        this.handleGoogleSignIn(googleProfile, idToken);
    }
     
    //https://developers.google.com/identity/gsi/web/reference/js-reference#CredentialResponse
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleGoogleSignIn(googleProfile: IGoogleProfile, idToken: any): void {
        this.loginService.signedInGoogleUser.next(googleProfile);        
        const googleLoginObject: IGoogleLoginObject = {
            email: googleProfile.email,
            firstName: googleProfile.givenName,
            lastName: googleProfile.familyName,
            token: idToken,
        };
        this.authService.googleLogin(googleLoginObject).subscribe(
            {
                next: (successResponse) => this.ngZone.run(() => {
                    // this is a workaround to a known issue
                    // https://github.com/angular/angular/issues/18254
                    if (this.returnUrl) {
                        void this.router.navigateByUrl(this.returnUrl);
                    } else {
                        void this.router.navigate(['/home']);
                    }
                }),
                error: (errorResponse) => {
                    if (errorResponse.status === 418) {
                        this.notificationsService.error(this.config.messageOverrides.googleSignInFailureEmail);
                    } else {
                        this.notificationsService.error(this.config.messageOverrides.googleSignInFailure);
                    }
                },
            }
        );
    }

    //https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private parseJwt (token: string): any {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    }
}
