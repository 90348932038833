import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@mt-ng2/auth-module';
import { EnvironmentService } from '@mt-ng2/environment-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { LoginConfig } from '../../libraries/login-config.library';

@Component({
    selector: 'app-two-factor',
    templateUrl: './two-factor.component.html',
})
export class TwoFactorComponent implements OnInit {
    twoFactorForm: FormGroup;
    logoFull: string;
    protected authService: AuthService;
    protected router: Router;
    protected notificationsService: NotificationsService;
    protected environmentService: EnvironmentService;
    public config: LoginConfig;

    constructor(injector: Injector) {
        this.authService = injector.get(AuthService);
        this.router = injector.get(Router);
        this.notificationsService = injector.get(NotificationsService);
        this.config = injector.get(LoginConfig);
        this.environmentService = injector.get(EnvironmentService);
        this.logoFull = this.config.useAssetsFolderForImages
            ? `${this.environmentService.config.assetsPath}logo-full.png`
            : `${this.environmentService.config.imgPath}logo-full.png`;
    }

    ngOnInit(): void {
        this.createForm();
    }

    createForm(): void {
        if (!this.twoFactorForm) {
            this.twoFactorForm = new FormGroup({});
        }
        this.twoFactorForm.addControl('otp', new FormControl('', [Validators.required, Validators.minLength(6)]));
        this.twoFactorForm.addControl('rememberDevice', new FormControl(false));
    }

    getErrorMessage(): string {
        const control = this.twoFactorForm.get('otp');
        switch (true) {
            case control.errors['required'] !== undefined:
                return '6-digit passcode is required.';
                break;
            case control.errors['minlength'] !== undefined:
                return 'Code must be 6 digits.';
                break;
            default:
                return '';
                break;
        }
    }

    hasError(): boolean {
        const control = this.twoFactorForm.get('otp');
        return control.touched && (control.hasError('required') || control.hasError('minlength'));
    }

    onSubmit(): void {
        if (this.twoFactorForm.valid) {
            const values = this.twoFactorForm.value;
            this.authService.twoFactorLogin(values.otp, values.rememberDevice).subscribe({
                complete: () => {
                    this.router.navigate(['/home']);
                },
            });
        }
    }
}
