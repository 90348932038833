import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Input, Inject, Optional } from '@angular/core';

import { ComponentErrorHandler } from '@mt-ng2/component-error-handler';
import { TypeAheadModuleConfigToken, ITypeAheadConfig, MatchingStrategy } from '@mt-ng2/type-ahead-control-config';

import { getValue } from './libraries/type-ahead.library';
import { MatcherFactory } from './libraries/MatchingFactories/MatcherFactory';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '(mousedown)': '$event.preventDefault()',
        class: 'dropdown-menu show',
        role: 'listbox',
    },
    styles: [
        `
            :host {
                border: none;
                padding: 0;
            }
            .list-group-item {
                padding-top: 2px;
                padding-bottom: 2px;
                padding-left: 10px;
                padding-right: 10px;
            }
            .totals-text {
                padding-left: 20px;
                font-size: small;
                font-style: italic;
            }
        `,
    ],
    template: `
        <ng-container *componentErrorHandler="errorHandler">
            <ul class="list-group">
                <li
                    *ngFor="let item of itemsToShow; let i = index"
                    class="list-group-item"
                    [class.active]="i === activeIndex"
                    [class.disabled]="isItemDisabled && isItemDisabled(item)"
                    (click)="select(item, i)"
                >
                    <highlighter [matchingStrategy]="matchingStrategy" [result]="getValue(item)" [term]="searchText"></highlighter>
                </li>
            </ul>
            <div *ngIf="scrollable">
                <span class="totals-text text-muted">showing {{ startingIndex + 1 }} - {{ startingIndex + maxToShow }} of {{ totalItems }}</span>
            </div>
        </ng-container>
    `,
})
export class TypeAheadWindowComponent implements OnInit {
    /**
     * Defines the type of matching we will use to highlight text
     */
    @Input() matchingStrategy: MatchingStrategy;
    @Input() isItemDisabled: (item) => boolean;

    items: any[];

    protected _searchText = '';
    set searchText(value: string) {
        this.startingIndex = 0;
        this.activeIndex = 0;
        this._searchText = value;
    }
    get searchText(): string {
        return this._searchText;
    }

    nameProperty: string;

    startingIndex = 0;
    maxToShow = 10;
    activeIndex = 0;
    totalItems = 0;

    get scrollable(): boolean {
        return this.maxToShow < this.totalItems;
    }

    onSelection = new EventEmitter<any>();

    errorHandler: ComponentErrorHandler;

    get itemsToShow(): any[] {
        const matcher = new MatcherFactory().createMatchingStrategy(this.matchingStrategy);
        const items = matcher.getItems(this.items, this.searchText, this.errorHandler, this.nameProperty);
        this.totalItems = items.length;
        return items.slice(this.startingIndex, this.startingIndex + this.maxToShow);
    }

    constructor(
        public cdr: ChangeDetectorRef,
        @Inject(TypeAheadModuleConfigToken)
        @Optional()
        protected typeAheadConfig: ITypeAheadConfig,
    ) { }

    ngOnInit(): void {
        // Prioritize the input strategy otherwise fall back on the config.
        if (this.matchingStrategy !== undefined) {
            return;
        } else if (this.typeAheadConfig && this.typeAheadConfig.matchingStrategy) {
            this.matchingStrategy = this.typeAheadConfig.matchingStrategy;
        }
    }

    getActive(): any {
        return this.itemsToShow[this.activeIndex];
    }

    getValue(item: any): string {
        return getValue(item, this.nameProperty, this.errorHandler);
    }

    moveActivePrevious(): void {
        if (this.itemsToShow[this.activeIndex - 1]) {
            this.activeIndex -= 1;
        } else if (this.scrollable) {
            if (this.startingIndex) {
                this.startingIndex -= 1;
                this.cdr.detectChanges();
            }
        }
    }

    moveActiveNext(): void {
        if (this.itemsToShow[this.activeIndex + 1]) {
            this.activeIndex += 1;
        } else if (this.scrollable) {
            if (this.startingIndex + this.maxToShow !== this.totalItems) {
                this.startingIndex += 1;
                this.cdr.detectChanges();
            }
        }
    }

    select(item: any, index: number): void {
        if (this.isItemDisabled?.(item)) {
            return;
        }
        this.activeIndex = index;
        this.onSelection.emit(item);
    }
}
