import { MtFileUploader } from './mt-file-uploader.library';

/**
 * Object representing an image
 * @property Name
 * @property ImagePath
 * @property ThumbnailPath
 */
export class IPhotoImage {
    Name: string;
    ImagePath: string;
    ImageFullPath?: string;
    ThumbnailPath: string;
    ThumbnailFullPath?: string;
    useInjectors?: boolean = false;
}

/**
 * Interface for MtPhotoComponentAPI
 * @property uploader
 */
export interface IMtPhotoComponentAPI {
    uploader: MtFileUploader;
}
