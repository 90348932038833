import { LoginService } from './../../services/login.service';
import { Component, ErrorHandler, Injector, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

import { EnvironmentService } from '@mt-ng2/environment-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { AuthConfig, AuthService, ILoginResponse, MtAuthGuard } from '@mt-ng2/auth-module';
import { PageTitlesService } from '@mt-ng2/page-titles';
import { RememberOptions } from '@mt-ng2/login-module-config';

import { LoginConfig } from '../../libraries/login-config.library';
import { RememberUserNameStorageService } from '../../libraries/storage.library';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;

    logoFull: string;
    returnUrl = '';

    get hasGoogleAuthConfig(): boolean {
        return this.config.googleAuthConfig ? true : false;
    }

    get hasMicrosoftAuthConfig(): boolean {
        return this.config.microsoftAuthConfig ? true : false;
    }

    get rememberOption(): RememberOptions {
        return this.config.rememberFeature.rememberOptions;
    }

    protected authService: AuthService;
    protected authConfig: AuthConfig;
    protected router: Router;
    protected notificationsService: NotificationsService;
    protected environmentService: EnvironmentService;
    public config: LoginConfig;
    protected pageTitlesService: PageTitlesService;
    protected activatedRoute: ActivatedRoute;
    protected rememberUserNameStorageService: RememberUserNameStorageService;
    protected loginService: LoginService;
    protected globalErrorHandler: ErrorHandler;

    constructor(injector: Injector) {
        this.authService = injector.get(AuthService);
        this.authConfig = injector.get(AuthConfig);
        this.router = injector.get(Router);
        this.notificationsService = injector.get(NotificationsService);
        this.environmentService = injector.get(EnvironmentService);
        this.config = injector.get(LoginConfig);
        this.pageTitlesService = injector.get(PageTitlesService);
        this.activatedRoute = injector.get(ActivatedRoute);
        this.rememberUserNameStorageService = injector.get(RememberUserNameStorageService);
        this.loginService = injector.get(LoginService);
        this.globalErrorHandler = injector.get(ErrorHandler);

        this.logoFull = this.config.useAssetsFolderForImages
            ? `${this.environmentService.config.assetsPath}logo-full.png`
            : `${this.environmentService.config.imgPath}logo-full.png`;
    }

    ngOnInit(): void {
        // appReady determines if an authenticated connection has been made.
        // While it's waiting it shows a loading icon.  When appReady has a
        // value the loading icon is hidden.  We always want this to be true
        // when you are on the login page.  Because you aren't authed!
        if (this.authService.appReady && !this.authService.appReady.getValue()) {
            this.authService.appReady.next(true);
        }
        this.createForm();
        this.setPageTitle();
        this.returnUrl = this.activatedRoute.snapshot.queryParams[MtAuthGuard.Return_Url_QueryParam];
    }

    setPageTitle(): void {
        this.pageTitlesService.setPageTitle('Login');
    }

    createForm(): void {
        this.rememberUserNameStorageService.getUserNameFromCookie().subscribe((remember) => {
            if (!this.loginForm) {
                this.loginForm = new FormGroup({});
            }
            this.loginForm.addControl('password', new FormControl(''));
            this.loginForm.addControl('username', new FormControl(remember || '', Validators.required));
            this.loginForm.addControl('rememberMe', new FormControl(false, Validators.required));
            this.loginForm.addControl('rememberUserName', new FormControl(remember ? true : false, Validators.required));
        });
    }

    showUsernameRequiredError(): boolean {
        const control = this.loginForm.get('username');
        return control.touched && control.hasError('required');
    }

    showPasswordRequiredError(): boolean {
        const control = this.loginForm.get('password');
        return control.touched && control.hasError('required');
    }

    onLogin(): void {
        if (this.loginForm.valid) {
            const values = this.loginForm.value;
            this.authService.login(values.username, values.password, values.rememberMe).subscribe(
                ((loginResponse: ILoginResponse) => {
                    if (values.rememberUserName) {
                        this.rememberUserNameStorageService.saveRememberUserName(values.username);
                    } else {
                        this.rememberUserNameStorageService.removeUserNameCookie();
                    }
                    if (loginResponse.AuthUserId) {
                        this.notificationsService.info(
                            'A one-time password has been emailed to you.  Click the link in the email to finish logging in.',
                        );
                        this.router.navigate([this.authConfig.paths.twoFactorVerifyPath]);
                    } else if (this.returnUrl) {
                        this.router.navigateByUrl(this.returnUrl);
                    } else {
                        this.router.navigate(['/home']);
                    }
                }).bind(this),
                (errorResponse: HttpErrorResponse) => {
                    if (errorResponse.status === 418) {
                        if (errorResponse.error === 'DomainLoginEmailSent') {
                            this.notificationsService.success('A login link has been emailed to you');
                        } else {
                            this.notificationsService.error(this.config.messageOverrides.userNamePasswordFailure);
                        }
                    } else if (errorResponse.status === 403) {
                        if (errorResponse.error && typeof errorResponse.error === 'string') {
                            // if error message (i.e. "account is locked") then display it
                            this.notificationsService.error(errorResponse.error);
                        } else {
                            // else rethrow the error
                            return throwError(errorResponse);
                        }
                    } else if (errorResponse.status === 400) {
                        if (errorResponse.error) {
                            if (typeof errorResponse.error === 'string') {
                                this.notificationsService.error(errorResponse.error);
                            } else if (typeof errorResponse.error?.ModelState?.Service === 'string') {
                                this.notificationsService.error(errorResponse.error.ModelState.Service);
                            } else {
                                this.globalErrorHandler.handleError(errorResponse);
                            }
                        }
                    }
                },
            );
        } else {
            markAllFormFieldsAsTouched(this.loginForm);
        }
    }
}
