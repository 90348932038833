import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder } from '@angular/forms';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

import { DynamicField, DynamicFieldTypes, LabelPosition, LabelPositions, IDynamicField } from '@mt-ng2/dynamic-form';
import { common } from '@mt-ng2/common-functions';
import { ComponentErrorHandler } from '@mt-ng2/component-error-handler';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'mt-managed-list',
    styleUrls: ['./mt-managed-list.component.less'],
    templateUrl: './mt-managed-list.component.html',
})
export class ManagedListComponent implements OnInit {
    copy: any;
    form: FormGroup;
    formArray: FormArray;

    @Input('dynamicForm') dynamicForm: any;
    @Input('sortPropertyName') sortPropertyName = 'Sort';
    @Input('fields') fields: string[];
    @Input('duplicateCheck') duplicateCheck = true;
    @Input('duplicateCheckFields') duplicateCheckFields: string[];
    @Input('componentTitle') title: string;
    @Input('sortable') sortable;
    @Input('canAdd') canAdd = true;
    @Input('canRemove') canRemove = true;
    @Input('canEdit') canEdit = true;
    @Input('addToTop') addToTop = false;
    @Output('onSave') onSave: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

    private _items: any[] = [];
    @Input('items')
    set items(value: any[]) {
        value = !value ? [] : value;
        // ensure one if we have the copy ready to push
        if (this.copy && value.length === 0) {
            value.push({ ...this.copy });
        }
        // set the variable
        this._items = value;

        // if we have already created the form object
        // then set it again with the new value
        // i.e. (when we have items updated)
        if (this.form) {
            this.setForm();
        }
    }
    get items(): any[] {
        return this._items;
    }
    public errorHandler = new ComponentErrorHandler('ManagedListComponent', '@mt-ng2/managed-list');
    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {
        if (!this.canEdit) {
            // if cannot edit, then can't add
            this.canAdd = false;
        }
        if (this.sortable === undefined) {
            this.sortable = this.dynamicForm.hasOwnProperty(this.sortPropertyName);
        }
        if (this.fields === undefined) {
            let fields: string[] = [];
            for (let key in this.dynamicForm) {
                if (this.dynamicForm[key] && key !== this.sortPropertyName) {
                    fields.push(key);
                }
            }
            this.fields = fields;
        }
        if (this.duplicateCheck && this.duplicateCheckFields === undefined) {
            this.duplicateCheckFields = this.fields;
        }
        let item = { Id: 0 };
        for (let key in this.dynamicForm) {
            if (this.dynamicForm[key]) {
                item[key] = this.dynamicForm[key].value;
            }
        }
        this.copy = item;
        this.setForm();
    }

    onlyUnique(value, index, self): any {
        let firstItem = self.find((item) => {
            let isMatch = true;
            this.duplicateCheckFields.forEach((field) => {
                if (value[field] !== item[field]) {
                    isMatch = false;
                }
            });
            return isMatch;
        });
        return self.indexOf(firstItem) === index;
    }

    duplicateItemsCheckerFunction(input: FormControl): any {
        if (!(this.duplicateCheck && this.currentFormArray && (<any>this.currentFormArray).value)) {
            return null;
        }
        let items = (<any>this.currentFormArray).value;
        let uniqueItems = items.filter(this.onlyUnique.bind(this));
        if (uniqueItems.length === items.length) {
            return null;
        }
        return {
            duplicateItems: true,
        };
    }

    hasDuplicateItems(): boolean {
        return this.currentFormArray && this.currentFormArray.errors && this.currentFormArray.errors.duplicateItems;
    }

    setForm(): void {
        // sort if sortable
        if (this.sortable) {
            common.sortByProperty(this.items, this.sortPropertyName);
        }
        this.form = this.fb.group({});
        let formGroups = this.items.map((item) => this.fb.group(item));
        this.formArray = this.fb.array(formGroups, this.duplicateItemsCheckerFunction.bind(this));
        this.form.addControl(this.cleanTitle, this.formArray);
    }

    get currentFormArray(): FormArray {
        return this.form.get(this.cleanTitle) as FormArray;
    }

    get cleanTitle(): string {
        return this.title.replace(/ +/g, '');
    }

    create(): void {
        const newItem = {};
        for (let key in this.copy) {
            if (this.copy.hasOwnProperty(key)) {
                if (key === 'Id') {
                    newItem[key] = 0;
                } else {
                    let df: DynamicField = this.dynamicForm[key];
                    if (df.type.fieldType === DynamicFieldTypes.Numeric) {
                        newItem[key] = 0;
                    } else {
                        newItem[key] = '';
                    }
                }
            }
        }
        let formGroup = this.fb.group(newItem);
        if (this.addToTop) {
            this.formArray.insert(0, formGroup)
        } else {
            this.formArray.push(formGroup);
        }
    }

    getColClass(): string {
        if (this.fields.length <= 0) return '';
        if (this.fields.length > 12) {
            this.errorHandler.addError('The maximum number of fields allowed is 12. If you need to exceed this please consider a custom control.');
            return '';
        }
        return `col-md-${Math.floor(12 / this.fields.length)}`;
    }

    getLabel(fieldName: string): string {
        let dynamicField = <DynamicField>{ ...this.dynamicForm[fieldName] };
        return dynamicField.label;
    }

    getField(form: FormGroup, fieldName: string): DynamicField {
        let fieldToCopy = <IDynamicField>{ ...this.dynamicForm[fieldName] };
        let dynamicField: DynamicField = new DynamicField({
            disabled: this.canEdit ? false : true,
            formGroup: fieldToCopy.formGroup,
            label: fieldToCopy.label,
            name: fieldToCopy.name,
            options: fieldToCopy.options,
            placeholder: fieldToCopy.placeholder,
            type: fieldToCopy.type,
            validation: fieldToCopy.validation,
            validators: fieldToCopy.validators,
            value: form.controls[fieldName].value,
        });
        dynamicField.labelPosition = new LabelPosition({
            position: LabelPositions.Hidden,
        });
        dynamicField.insideBoxValidation = true;
        return dynamicField;
    }

    delete(index: number): void {
        this.formArray.removeAt(index);
    }

    update(): void {
        if (this.sortable) {
            let count = 1;
            (<any>this.form.controls[this.cleanTitle]).controls.forEach((element) => {
                let controlItem = (<any[]>this.form.value[this.cleanTitle]).find((eachItem) => eachItem === element.value);
                controlItem[this.sortPropertyName] = count;
                count++;
            });
        }
        this.onSave.emit(this.form);
    }

    drop(event: CdkDragDrop<any, any>): void {
        this.formArray.controls.splice(event.currentIndex, 0, this.formArray.controls.splice(event.previousIndex, 1)[0]);
    }
}
