import { Component, ContentChild, TemplateRef, OnInit, OnDestroy, Injector } from '@angular/core';
import { Subscription } from 'rxjs';

import { NavService } from '../nav.service';
import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { EnvironmentService } from '@mt-ng2/environment-module';
import { getProfileImgPathFromCurrentUser } from '../nav-sidebar/nav-sidebar.library';

@Component({
    selector: `header-current-user-details`,
    styles: [
        `
            .navbar-nav .user-menu {
                cursor: pointer;
            }
            .navbar-nav > .user-menu > .dropdown-menu > li.user-header {
                height: inherit;
            }
            .navbar-nav > .user-menu > .user-dropdown-toggle {
                padding-left: 8px;
                padding-right: 8px;
            }
            .sign-out-button {
                margin-bottom: 15px;
            }
            .navbar-nav > .user-menu > .dropdown-menu > li.user-header > p.custom-user-details {
                z-index: 5;
                color: rgba(255, 255, 255, 0.8);
                font-size: 14px;
                margin: 0;
                margin-bottom: 10px;
            }
        `,
    ],
    styleUrls: [`./nav-header.styles.less`],
    template: `
        <ul class="nav navbar-nav">
            <!-- User Account: style can be found in dropdown.less -->
            <li ngbDropdown class="dropdown user user-menu">
                <a ngbDropdownToggle class="dropdown-toggle user-dropdown-toggle" data-toggle="dropdown">
                    <ng-container
                        *ngTemplateOutlet="
                            dropdownToggleTemplate;
                            context: { userName: this.userName, userImageUrl: this.userImageUrl, logout: this.logout.bind(this) }
                        "
                    >
                    </ng-container>
                    <span *ngIf="!dropdownToggleTemplate">
                        <i class="fa fa-user" aria-hidden="true"></i>
                        <b class="hidden-sm hidden-xs">{{ userName }}</b>
                    </span>
                </a>
                <ul ngbDropdownMenu class="dropdown-menu ''" display="dynamic">
                    <li class="user-header">
                        <ng-container
                            *ngTemplateOutlet="
                                dropdownMenuTemplate;
                                context: { userName: this.userName, userImageUrl: this.userImageUrl, logout: this.logout.bind(this) }
                            "
                        >
                        </ng-container>
                        <ng-container *ngIf="!dropdownMenuTemplate">
                            <!-- User image -->
                            <img [src]="userImageUrl" class="img-circle" alt="Profile Image" />
                            <p>
                                {{ userName }}
                            </p>
                            <p *ngIf="customUserDetailsHtml" class="custom-user-details" [innerHtml]="customUserDetailsHtml"></p>
                            <button class="btn btn-warning btn-flat sign-out-button" (click)="logout()">Sign out</button>
                        </ng-container>
                    </li>
                </ul>
            </li>
        </ul>
    `,
})
export class HeaderCurrentUserDetailsComponent implements OnInit, OnDestroy {
    @ContentChild('dropdownToggleTemplate', { read: TemplateRef })
    dropdownToggleTemplate;
    @ContentChild('dropdownMenuTemplate', { read: TemplateRef })
    dropdownMenuTemplate;

    userName: string;
    userImageUrl: string;
    customUserDetailsHtml: string;

    subscriptions = new Subscription();

    protected navService: NavService;
    protected authService: AuthService;
    protected environmentService: EnvironmentService;

    constructor(injector: Injector) {
        this.navService = injector.get(NavService);
        this.authService = injector.get(AuthService);
        this.environmentService = injector.get(EnvironmentService);
    }

    ngOnInit(): void {
        this.setCurrentUserVariables(this.authService.currentUser.getValue());
        this.subscriptions.add(this.authService.currentUser.subscribe((currentUser: any) => this.setCurrentUserVariables(currentUser)));

        this.customUserDetailsHtml = this.navService.customUserDetailsHtml.getValue();
        this.subscriptions.add(
            this.navService.customUserDetailsHtml.subscribe((userDetailsHtml: string) => {
                this.customUserDetailsHtml = userDetailsHtml;
            }),
        );
    }

    private setCurrentUserVariables(currentUser: ILoggedIn): void {
        this.userName = currentUser.Name || 'User';
        this.userImageUrl = getProfileImgPathFromCurrentUser(currentUser, this.environmentService);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    logout(): void {
        this.authService.logout();
    }
}
