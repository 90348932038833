import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { UUID } from 'angular2-uuid';

import { StorageService } from '@mt-ng2/common-classes';
import { EnvironmentService } from '@mt-ng2/environment-module';

import { ILoginToken, I2faInfo } from '../libraries/token-service.library';

@Injectable({
    providedIn: 'root',
})
export class TokenService {
    private readonly deviceExpireDays = 30;
    private readonly deviceCookieExpireDays = this.deviceExpireDays * 2;

    tokenKey = '';
    xsrfTokenKey = '';
    twoFactorKey = '';
    deviceIdKey = '';

    refreshTokenInProgress: boolean;

    constructor(private environmentService: EnvironmentService, private storageService: StorageService) {
        const tokenPrefix = `${this.environmentService.config.appName}-${this.environmentService.config.appVersion}`;
        this.tokenKey = `${tokenPrefix}-jwt`;
        this.xsrfTokenKey = `${tokenPrefix}-XSRF-TOKEN`;
        this.twoFactorKey = `${tokenPrefix}-2fa`;
        this.deviceIdKey = `${tokenPrefix}-device-id`;
    }

    /**
     * Return the currently saved token from the cookie
     */
    getTokenFromCookie(): Observable<string> {
        // pull token from cookie
        return from(this.storageService.get(this.tokenKey));
    }

    /**
     * Save the jwt token in the cookie
     */
    saveJwtTokenInCookie(tokenObj: ILoginToken): void {
        if (tokenObj.remember) {
            const cookieExpiration = new Date();
            // save refresh token in cookie for 1 month on each refresh
            // auth token will expire before cookie does but refresh will not
            cookieExpiration.setDate(cookieExpiration.getDate() + 30);
            this.storageService.set(this.tokenKey, JSON.stringify(tokenObj), { expires: cookieExpiration });
        } else {
            this.storageService.set(this.tokenKey, JSON.stringify(tokenObj), { expires: '' });
        }
    }

    private getDeviceIdKey(username: string): string {
        return `${this.deviceIdKey}-${username?.toLowerCase()}`;
    }

    private generateDeviceId(): string {
        return UUID.UUID();
    }

    private getDeviceIdExpiration(): Date {
        const expiration = new Date();
        expiration.setDate(expiration.getDate() + this.deviceCookieExpireDays);
        return expiration;
    }

    getDeviceId(username: string): Observable<string> {
        const key = this.getDeviceIdKey(username);
        return from(this.storageService.get(key)).pipe(
            map((deviceId) => {
                if (!deviceId) {
                    deviceId = this.generateDeviceId();
                    this.storageService.set(key, deviceId, { expires: this.getDeviceIdExpiration() });
                }
                return deviceId;
            }),
        );
    }

    refreshDeviceId(username: string): string {
        const key = this.getDeviceIdKey(username);
        const deviceId = this.generateDeviceId();
        this.storageService.set(key, deviceId, { expires: this.getDeviceIdExpiration() });
        return deviceId;
    }

    get2faInfoFromCookie(): Observable<I2faInfo> {
        return from(this.storageService.get(this.twoFactorKey)).pipe(
            map((infoString) => {
                if (!infoString) {
                    return null;
                }
                return JSON.parse(infoString) as I2faInfo;
            }),
        );
    }

    save2faInfoInCookie(info: I2faInfo): void {
        const cookieExpiration = new Date();
        cookieExpiration.setHours(cookieExpiration.getHours() + 2);
        this.storageService.set(
            this.twoFactorKey,
            JSON.stringify(info),
            { expires: cookieExpiration },
        );
    }

    /**
     * Return the currently saved xsrf token from the cookie
     */
    getXsrfTokenFromCookie(): Observable<string> {
        // pull token from cookie
        return from(this.storageService.get(this.xsrfTokenKey));
    }

    /**
     * Save the xsrf token in the cookie
     */
    saveXsrfTokenInCookie(tokenObj: string, remember: boolean): void {
        if (remember) {
            const cookieExpiration = new Date();
            cookieExpiration.setDate(cookieExpiration.getDate() + 30);
            this.storageService.set(this.xsrfTokenKey, tokenObj, { expires: cookieExpiration });
        } else {
            this.storageService.set(this.xsrfTokenKey, tokenObj, {
                expires: '',
            });
        }
    }

    /**
     * Clear jwt and xsrf token from cookies and observables
     */
    clearTokens(): void {
        this.storageService.remove(this.tokenKey);
        this.storageService.remove(this.xsrfTokenKey);
    }
}
