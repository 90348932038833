import { Injectable, Injector } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { NotificationsService } from '@mt-ng2/notifications-module';

import { ClaimsService } from '../services/claims.service';
import { AuthService } from '../services/auth.service';
import { AuthConfig } from '../libraries/auth-config.library';
import { MtAuthGuard } from './mt-auth-guard.library';

@Injectable()
export class MtCanLoadAuthGuard implements CanLoad {
    public window = window;

    public authService: AuthService;
    public claimsService: ClaimsService;
    public router: Router;
    public notificationsService: NotificationsService;
    public authConfig: AuthConfig;

    constructor(injector: Injector) {
        this.authService = injector.get(AuthService);
        this.claimsService = injector.get(ClaimsService);
        this.router = injector.get(Router);
        this.notificationsService = injector.get(NotificationsService);
        this.authConfig = injector.get(AuthConfig);
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
        return this.authService
            .isAuthenticated()
            .pipe(
                map((isAuthenticated) => {
                    // user not signed in, send to login page
                    if (!isAuthenticated) {
                        if (!this.router.url.startsWith(this.authConfig.paths.loginPath)) {
                            const attemptedUrl = this.window.location.href.split('/#')[1];
                            if (attemptedUrl) {
                                this.router.navigate([this.authConfig.paths.loginPath], {
                                    queryParams: { [MtAuthGuard.Return_Url_QueryParam]: attemptedUrl },
                                });
                            } else {
                                this.router.navigate([this.authConfig.paths.loginPath]);
                            }
                            this.notificationsService.info(this.authConfig.messages.requiresLoginMessage);
                        }
                        return false;
                    }
                    return true;
                }, this),
            )
            .pipe(
                catchError((error) => {
                    this.router.navigate([this.authConfig.paths.loginPath]);
                    this.notificationsService.info(this.authConfig.messages.requiresLoginMessage);
                    return of(false);
                }),
            );
    }
}
