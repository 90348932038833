import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { IPathNotFoundConfig } from '../models/path-not-found-config.model';

export const PathNotFoundConfigToken = new InjectionToken<IPathNotFoundConfig>('path_not_found_config');

@Injectable()
export class PathNotFoundConfig {
    suggestedRoutes: string[];

    constructor(@Inject(PathNotFoundConfigToken) @Optional() private config: IPathNotFoundConfig) {
        if (config?.suggestedRoutes) {
            const pathStrings = config.suggestedRoutes.map((routes) => {
                if (typeof routes === 'string') {
                    return [routes];
                }
                return Object.keys(routes).map((key) => routes[key]);
            });
            const flattenedPaths = Array.prototype.concat(...pathStrings);
            const distinctPaths = new Set(flattenedPaths);
            this.suggestedRoutes = [...distinctPaths];
        } else {
            this.suggestedRoutes = [];
        }
    }
}
