import Quill from 'quill';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

class Backtick {
    backtickLength: number;
    backtickStart: number;
    formatLength: number;
    backtickEnd: number;

    get codeBlockTextStart(): number {
        return this.backtickStart + this.backtickLength;
    }

    get codeBlockTextEnd(): number {
        return this.backtickEnd - this.backtickLength;
    }

    constructor(matches: RegExpExecArray) {
        this.backtickLength = matches[1].length;
        this.backtickStart = matches.index;
        this.formatLength = matches[2].length;
        this.backtickEnd = this.backtickStart + matches[0].length;
    }
}
export default class BacktickReplacer {
    private textChangeSubject: Subject<void>;
    private $textChange: Observable<void>;

    constructor(private quill: Quill, private options) {
        this.textChangeSubject = new Subject();
        this.$textChange = this.textChangeSubject.asObservable();
        this.quill.on('text-change', (delta, oldContents, source) => {
            if (source === 'user') {
                this.textChangeSubject.next();
            }
        });
        const textChangeDelayTime = 550; // default set to be high enough to be above default windows keyboard repeat delay
        this.$textChange.pipe(debounceTime(textChangeDelayTime)).subscribe(() => {
            let text = this.quill.getText();
            const codeBlockRegex = /(`+)([^`]+)\1/gm;
            let matchArray: RegExpExecArray;
            while ((matchArray = codeBlockRegex.exec(text)) != null) {
                const format = matchArray[2].endsWith('\n') ? 'code-block' : 'code';
                this.replaceBacktickMatches(matchArray, format);
            }
        });
    }

    replaceBacktickMatches(matchArray: RegExpExecArray, format: 'code-block' | 'code'): void {
        const backtickInfo = new Backtick(matchArray);
        this.quill.formatText(backtickInfo.codeBlockTextStart, backtickInfo.formatLength, format, true);
        this.quill.formatText(backtickInfo.codeBlockTextStart + backtickInfo.formatLength, 1, format, false);
        this.quill.deleteText(backtickInfo.backtickStart, backtickInfo.backtickLength);
        this.quill.deleteText(backtickInfo.codeBlockTextEnd - backtickInfo.backtickLength, backtickInfo.backtickLength); // need to subtract since we just deleted that many
    }
}
