import { Injectable, InjectionToken, Injector } from '@angular/core';

import { IAuthConfig, IAuthConfigMessageOverrides, IAuthConfigPathOverrides, IAuthConfigEventHandlers } from '../models/auth-module-config';

const defaultConfig: IAuthConfig = {
    messageOverrides: {
        missingPermissionMessage: 'You do not have permission to access this page',
        requiresLoginMessage: 'Looks like you are not signed in, please login',
    },
    pathOverrides: {
        homePath: '/home',
        loginPath: '/login',
        myProfilePath: '/users/my-profile',
        twoFactorVerifyPath: '/verify',
    },
};

export const AuthModuleConfigToken = new InjectionToken<IAuthConfig>('auth_module_config');

@Injectable({
    providedIn: 'root',
})
export class AuthConfig {
    messages: IAuthConfigMessageOverrides;
    paths: IAuthConfigPathOverrides;
    eventHandlers: IAuthConfigEventHandlers;

    constructor(injector: Injector) {
        let config: IAuthConfig = injector.get(AuthModuleConfigToken, {});
        if (!config) {
            config = {};
        }
        this.messages = Object.assign({}, defaultConfig.messageOverrides, config.messageOverrides);
        this.paths = Object.assign({}, defaultConfig.pathOverrides, config.pathOverrides);
        this.eventHandlers = Object.assign({}, config.eventHandlers);
    }
}
