import { Observable } from 'rxjs';
import { take, finalize } from 'rxjs/operators';

/**
 * utility function that runs a before handler if present and safely handles if not present
 *
 * NOTE:  Do not use outside of AuthModule.  If needed outside this module, add to the common
 * module or make a new module for these (discuss with NGIN).
 * @example
 * handlerExecuteSafe(
 *     logoutDetails.onBeforeLogoutHandler, // may have a before handler OR may be null
 *     () => this.processLogout(logoutDetails.currentUser, bypassRoute), // what to do either way
 * );
 * @param handler - observable to await if present
 * @param after - function to run either way.  Runs even if handler was present but returned unsuccessful.
 */
export function handlerExecuteSafe(handler: Observable<void>, after: () => void): void {
    if (handler) {
        handler.pipe(take(1), finalize(after)).subscribe();
    } else {
        after();
    }
}
