import { InjectionToken } from '@angular/core';
import { MicrosoftAuthConfig } from './ms-login-config';

export interface IGoogleAuthConfig {
    googleApiKey: string;
    autoPrompt?: boolean;
}

export class GoogleAuthConfig implements IGoogleAuthConfig {
    public googleApiKey: string;
    autoPrompt?: boolean;

    constructor(options: IGoogleAuthConfig) {
        Object.assign(this, options);
    }
}

export class LoginComponentLink {
    constructor(public linkHtml: string, public routerLinkPath: string) {}
}

/**
 * @property failedPattern: Message that displays when the password complexity doesn't meet the requirements
 * @property forgotPasswordFailure: Error displayed when the forgot password endpoint fails
 * @property forgotPasswordLabel: Text that displays to the user above the forgot password form
 * @property forgotPasswordSuccess: Success displayed when the forgot password endpoint succeeds
 * @property googleSignInFailure: Error displayed when the Google login endpoint fails
 * @property googleSignInFailureEmail: Error displayed when the Google email doesn't exist in our system.
 * @property googleSignInSuccess: Success displayed when the Google login endpoint succeeds
 * @property loginLabel: Label text displayed to the user on the login screen
 * @property signInButtonText: Text displayed inside the sign-in button
 * @property userNamePasswordFailure: Error displayed when login endpoint returns a failure
 * @property rememberDeviceLabel: Label for the checkbox to remember the device when entering 2FA code
 */
export interface IMessageOverrides {
    failedPattern?: string;
    forgotPasswordFailure?: string;
    forgotPasswordLabel?: string;
    forgotPasswordSuccess?: string;
    googleSignInFailure?: string;
    googleSignInFailureEmail?: string;
    microsoftSignInFailure?: string;
    microsoftSignInFailureEmail?: string;
    loginLabel?: string;
    resetPasswordLabel?: string;
    signInButtonText?: string;
    resetPasswordSaveButtonText?: string;
    resetPasswordBackButtonText?: string;
    userNamePasswordFailure?: string;
    rememberDeviceLabel?: string;
}

export type RememberOptions = 'userName' | 'rememberMe' | 'none';

export interface IRememberFeature {
    rememberOptions: RememberOptions;
}

/**
 * Interface representing the customizable properties
 * of the login module.
 * @example
 * const loginModuleConfig: ILoginModuleConfig = {
 *     hideRegularSignIn: true,
 * };
 * @property googleAuthConfig: API Key for google oauth account
 * @property hideRegularSignIn: Hides the username/password ui and just displays 3rd party oauth
 * @property microsoftAuthConfig: Configuration for microsoft oauth account
 * @property passwordPattern: Regex patter for validating the password
 * @property loginComponentLinks: An array of links to display below the sign-in form
 * @property messageOverrides: An object for overriding all the notification messages in the login module.
 * @property rememberFeature: An object for configuring remember feature.
 */
export interface ILoginConfig {
    googleAuthConfig?: GoogleAuthConfig;
    hideRegularSignIn?: boolean;
    microsoftAuthConfig?: MicrosoftAuthConfig;
    passwordPattern?: string;
    loginComponentLinks?: LoginComponentLink[];
    messageOverrides?: IMessageOverrides;
    rememberFeature?: IRememberFeature;
    useAssetsFolderForImages?: boolean;
}

/**
 * Token used for providing the ILoginModuleConfig
 * @example
 * // in the app or shared modules providers section
 * providers: [
 *     { provide: LoginModuleConfigToken, useValue: loginModuleConfig },
 * ],
 */
export const LoginModuleConfigToken = new InjectionToken<ILoginConfig>('login_module_config');
