import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { isNullOrWhitespace, isNull } from '@mt-ng2/common-functions';

import { TokenService } from '../services/token.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private authService: AuthService;
    private tokenService: TokenService;

    constructor(private injector: Injector) {}

    /**
     * Interceptor that will check if you are making an authorized request.
     * If you are it will attach the token information, if not it will only
     * attach the xsrf token.
     * @param request
     * @param next
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.authService) {
            this.authService = this.injector.get(AuthService);
        }
        if (!this.tokenService) {
            this.tokenService = this.injector.get(TokenService);
        }

        return forkJoin([this.authService.getLoginTokenFromCookie(), this.tokenService.getXsrfTokenFromCookie()]).pipe(
            mergeMap(([tokenObj, xsrfToken]) => {
                if (isNull(tokenObj) || !tokenObj.token || isNullOrWhitespace(xsrfToken)) {
                    return next.handle(request);
                }
                const unauthorizedRequest = request.headers.get('BypassAuth');
                let headers = {};
                if (unauthorizedRequest) {
                    headers = {
                        'X-XSRF-TOKEN': xsrfToken,
                    };
                } else {
                    // on a refresh request, the token is already attached
                    if (!request.headers.get('X-XSRF-TOKEN') && !request.headers.get('Authorization')) {
                        headers = {
                            Authorization: `Bearer ${tokenObj.token}`,
                            'X-XSRF-TOKEN': xsrfToken,
                        };
                    }
                }

                const dupReq = request.clone({
                    setHeaders: headers,
                });

                return next.handle(dupReq);
            }),
        );
    }
}
