import { Component, EventEmitter, Input, Output, OnInit, OnDestroy, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ISelectionChangedEvent, MultiselectItem } from '../libraries/multiselect.library';

@Component({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiselectComponent),
        },
    ],
    selector: 'multiselect',
    styleUrls: ['./multiselect.component.less'],
    templateUrl: './multiselect.component.html',
})
export class MultiselectComponent implements OnInit, OnDestroy, ControlValueAccessor {
    // *** CONTROL VALUE ACCESSOR ***
    /* tslint:disable:member-ordering */
    writeValue(obj: any): void {
        this.handleWriteValue(obj);
    }
    protected onValueChanged: (value: any) => void;
    registerOnChange(fn: any): void {
        this.onValueChanged = fn;
    }
    protected onTouched: () => void;
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    // *** END -- CONTROL VALUE ACCESSOR ***

    @Input() items: MultiselectItem[];
    @Input() valueIdAccessor = 'Id';
    @Input() placeholder: string;
    @Input() entity: string;
    @Input() showSelectAllButtons = true;
    @Input() filterMode = true;
    @Input() maxToShowInSelectedText: number;
    @Input() disabled = false;
    @Input() autoClose: boolean | 'outside' | 'inside';
    @Output() blur: EventEmitter<any> = new EventEmitter<any>();
    @Output() focus: EventEmitter<any> = new EventEmitter<any>();
    @Output('onSelectionChanged') selectionChanged: EventEmitter<ISelectionChangedEvent> = new EventEmitter<ISelectionChangedEvent>();

    hasFocus = false;

    constructor() {}

    ngOnInit(): void {
        if (!this.placeholder) {
            this.placeholder = this.entity ? `no ${this.entity} selected` : 'nothing selected';
        }
        if (this.maxToShowInSelectedText === undefined) {
            this.maxToShowInSelectedText = 3;
        }
        if (this.autoClose === undefined) {
            this.autoClose = 'outside';
        }
    }

    ngOnDestroy(): void {
        if (this.hasFocus) {
            this.blur.emit();
        }
    }

    get allSelected(): boolean {
        return this.items.every((item) => item.Selected);
    }

    get noneSelected(): boolean {
        return this.items.every((item) => !item.Selected);
    }

    get countOfSelected(): number {
        return this.items.filter((item) => item.Selected).length;
    }

    protected get selectedItems(): any[] {
        return [...this.items.filter((item) => item.Selected).map((item) => item.Item)];
    }

    selectedItemsText(): string {
        if (this.filterMode) {
            if (this.allSelected) {
                return `Any ${this.entity}`;
            }

            if (this.noneSelected) {
                return `${this.entity}`;
            }
        } else {
            if (this.noneSelected) {
                return `<em>${this.placeholder}</em>`;
            }
        }

        if (!this.maxToShowInSelectedText || this.countOfSelected <= this.maxToShowInSelectedText) {
            return this.items
                .filter((item) => item.Selected)
                .map((item) => this.getItemText(item))
                .join(', ');
        }

        let returnText = this.items
            .filter((item) => item.Selected)
            .slice(0, this.maxToShowInSelectedText)
            .map((item) => this.getItemText(item))
            .join(', ');

        return `${returnText} +${this.countOfSelected - this.maxToShowInSelectedText} more`;
    }

    getItemText(item: MultiselectItem): string {
        return item && item.Item ? item.Item.Name : '';
    }

    itemSelected(index: number): void {
        const selectedItem = this.items[index];
        selectedItem.Selected = !selectedItem.Selected;
        this.emitSelectionChangedEvent();
    }

    selectAll(checkAll: boolean): void {
        this.items.forEach((item) => {
            item.Selected = checkAll;
        });
        this.emitSelectionChangedEvent();
    }

    emitSelectionChangedEvent(): void {
        const event: ISelectionChangedEvent = {
            items: [...this.items],
            selectedItems: this.selectedItems,
        };
        this.selectionChanged.emit(event);
        this.updateValue();
    }

    protected updateValue(): void {
        if (!this.onValueChanged) {
            return;
        }
        let value = this.selectedItems;
        if (value?.length && this.valueIdAccessor && value[0][this.valueIdAccessor]) {
            value = value.map((item) => item[this.valueIdAccessor]);
        }
        value = value?.length ? value : null; // set to null if no values in array
        this.onValueChanged(value);
    }

    protected handleWriteValue(value: any[]): void {
        this.items.forEach((item) => {
            if (!value?.length) {
                item.Selected = false;
            } else if (this.valueIdAccessor) {
                item.Selected = value.some((id) => item.Item[this.valueIdAccessor] === id);
            } else {
                item.Selected = value.some((valueItem) => JSON.stringify(item.Item) === JSON.stringify(valueItem));
            }
        });
    }

    openChange(dropdownOpen: boolean): void {
        if (dropdownOpen) {
            this.hasFocus = true;
            this.focus.emit();
        } else {
            this.hasFocus = false;
            this.blur.emit();
        }
        this.onTouched?.();
    }
}
