import { ILoggedIn } from '@mt-ng2/auth-module';
import { EnvironmentService } from '@mt-ng2/environment-module';
import { common } from '@mt-ng2/common-functions';

export function getProfileImgPathFromCurrentUser(currentUser: ILoggedIn, environmentService: EnvironmentService): string {
    if (common.isNullOrWhitespace(currentUser.ProfileImagePath) || currentUser.ProfileImagePath === 'default.png') {
        return `${environmentService.config.assetsPath}noimage.png`;
    }

    if (isURL(currentUser.ProfileImagePath)) {
        return currentUser.ProfileImagePath;
    }

    const profilePath = environmentService.config.imgUploadPath || environmentService.config.imgPath;
    return profilePath + currentUser.ProfileImagePath;
}

function isURL(str: string): boolean {
    let pattern = new RegExp('https?://(www.)?[-a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)');
    return pattern.test(str);
}
