import { Observable } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';

/**
 * Pipe to grab an image or other piece of media from the API, with authentication.
 *
 * Usage: <img [src]="urlFromVariable | secureBlob | async" />
 */
@Pipe({
    name: 'secureBlob',
})
export class SecureBlobPipe implements PipeTransform {
    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

    transform(url: string): Observable<SafeUrl> {
        return this.http.get(url, { responseType: 'blob' }).pipe(map((res) => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(res))));
    }
}
