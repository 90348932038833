import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { EnvironmentService } from '@mt-ng2/environment-module';
import { FileItem } from 'ng2-file-upload';
import { ImageCropperComponent, ICropperSettings } from 'ngx-img-cropper';

import { IAfterAddingPhotoEvent, AfterAddingPhotoEvent, MtBlob, IMtBlobFile } from './libraries/photo.library';
import { MtFileUploader } from './libraries/mt-file-uploader.library';
import { IMtPhotoComponentAPI, IPhotoImage } from './libraries/mt-photo.component.library';
import { ImageCropperHelper } from './libraries/img-cropper-helper.library';
import { MtCropperSettingsHelper } from './libraries/mt-cropper-settings.library';
import { IMtCropperSettings, IMtFileUploaderSettings } from '@mt-ng2/photo-control-config';

@Component({
    selector: 'mt-photo',
    styleUrls: ['./mt-photo.component.css'],
    templateUrl: './mt-photo.component.html',
})
export class MtPhotoComponent implements OnInit {
    @Input() photo: IPhotoImage;
    @Input() cropperConfig: IMtCropperSettings;
    @Input() uploaderConfig: IMtFileUploaderSettings;
    @Input() headerTitle = 'Upload Photo';
    @Input() emptyImagePath: string;

    @Output('onDeletePhoto') onDeletePhoto: EventEmitter<any> = new EventEmitter<any>();
    @Output('onAfterAddingPhoto') onAfterAddingPhoto: EventEmitter<IAfterAddingPhotoEvent> = new EventEmitter<IAfterAddingPhotoEvent>();
    @Output('onAfterFileCropped') onAfterFileCropped: EventEmitter<IMtBlobFile> = new EventEmitter<IMtBlobFile>();
    @Output('ready') ready: EventEmitter<IMtPhotoComponentAPI> = new EventEmitter<IMtPhotoComponentAPI>();

    @ViewChild('cropper') cropper: ImageCropperComponent;
    @ViewChild('uploadSelect') uploadSelect: any;

    uploader: MtFileUploader;

    uploadedPhoto = false;
    data: any = {};

    ngxCropperSettings: ICropperSettings;

    noImagePath: string;
    imagePathAndThumbnailPath: string;

    constructor(private environmentService: EnvironmentService) {}

    ngOnInit(): void {
        this.ngxCropperSettings = MtCropperSettingsHelper.getNgxImageCropperSettings(this.cropperConfig);

        this.uploader = new MtFileUploader(this.uploaderConfig);
        this.uploader.onAfterAddingAll = this.afterPhotoAdded.bind(this);

        this.buildImagePaths();

        this.ready.emit({
            uploader: this.uploader,
        });
    }

    private buildImagePaths(): void {
        const imagePath = this.environmentService.config.imgUploadPath || this.environmentService.config.imgPath;
        this.noImagePath = this.emptyImagePath || this.environmentService.config.imgPath + 'noimage.png';
        this.imagePathAndThumbnailPath = imagePath;
        if (this.photo) {
            this.imagePathAndThumbnailPath = this.photo.ThumbnailFullPath || (imagePath + this.photo.ThumbnailPath);
            if (this.photo.useInjectors) {
                const imgPath = this.photo.ThumbnailFullPath || this.photo.ThumbnailPath;
                this.imagePathAndThumbnailPath = imgPath;
            }
        }

    }

    deletePhoto(): void {
        this.onDeletePhoto.emit();
        this.photo = null;
        this.uploadedPhoto = false;
        this.uploader.clearQueue();
        this.uploadSelect.nativeElement.value = '';
    }

    afterPhotoAdded(file: FileItem): void {
        if (this.uploader.fileHasError) {
            return;
        }
        this.onAfterAddingPhoto.emit(new AfterAddingPhotoEvent(file));
    }

    cropped(): void {
        if (this.uploader.fileHasError) {
            this.cropper.reset();
            return;
        }

        const croppedFile = this.buildCroppedFile();
        this.onAfterFileCropped.emit(croppedFile);
    }

    private buildCroppedFile(): IMtBlobFile {
        const file = MtBlob.blobToFile(MtBlob.convertToBlob(this.data.image));
        const fileQueueLength = this.uploader.queue.length;
        file.name = this.uploader.queue[fileQueueLength - 1].file.name;
        return file;
    }

    readFile(file: File): void {
        if (this.uploader.fileHasError) {
            this.uploader.fileHasError = false;
            return;
        }
        ImageCropperHelper.loadImageInCropper(file, this.cropper);
        this.uploadedPhoto = true;
    }
}
