import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { levenshtein } from '@mt-ng2/common-functions';
import { PathNotFoundConfig } from '../libraries/path-not-found-config.library';

@Injectable()
export class PathNotFoundResolveService implements Resolve<string | null> {

    constructor(private config: PathNotFoundConfig) {}
    /**
     * Function that runs just before a route is resolved. In this resolver
     * we are taking the user entered route that doesn't match any known routes
     * and attempting to guess what route they meant
     * @param route ActivatedRouteSnapshot route.
     * @param state RouterStateSnapshot state.
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string | null {
        const typoPath = state.url.replace('/', '');
        const threshold = this.getThreshold(typoPath);
        const dictionary = this.config.suggestedRoutes.filter((appPaths) => Math.abs(appPaths.length - typoPath.length) < threshold);

        if (!dictionary.length) {
            return null;
        }

        this.sortByDistances(typoPath, dictionary);

        return `/${dictionary[0]}`;
    }

    /**
     * Defines the maxium delta between 2 strings where a possible match will be returned
     * @param path String path.
     */
    getThreshold(path: string): number {
        if (path.length < 5) {
            return 3;
        }
        return 5;
    }

    /**
     * Sort the possible matches by levenshtein distance and return the first value.
     * This will be the lowest distance or best match
     * @param typoPath String typePath.
     * @param dictionary String[] dictionary.
     */
    sortByDistances(typoPath: string, dictionary: string[]): void {
        const pathsDistance = {} as { [name: string]: number };

        dictionary.sort((a, b) => {
            if (!(a in pathsDistance)) {
                pathsDistance[a] = levenshtein(a, typoPath);
            }
            if (!(b in pathsDistance)) {
                pathsDistance[b] = levenshtein(b, typoPath);
            }
            return pathsDistance[a] - pathsDistance[b];
        });
    }
}
