import { BrowserCacheLocation, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { ILoginConfig } from '@mt-ng2/login-module-config';

export function msalInstanceFactory(loginConfig: ILoginConfig): IPublicClientApplication {
    return new PublicClientApplication({
        auth: loginConfig.microsoftAuthConfig,
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
        },
    });
}
