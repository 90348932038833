/**
 * Enum for shape of cropping tool
 */
export enum Shape {
    Rectangle = 1,
    Circle = 2,
}

/**
 * Interface defining MT's version of cropper settings
 * @property {number} canvasHeight - canvas DOM element height
 * @property {number} canvasWidth - canvas DOM element width
 * @property {number} croppedHeight - resulting image height
 * @property {number} croppedWidth - resulting image width
 * @property {number} height - crop height
 * @property {number} width - crop width
 * @property {number} minHeight - minimum crop height
 * @property {number} minWidth - minimum crop width
 * @property {boolean} cropFromOriginalResolution - flag indicating whether resulting image will be cropped from the image's original resolution : default is true
 * @property {boolean} hideFileInput - flag hides file input element from cropper canvas
 * @property {boolean} keepAspect - if true, the aspect ratio of width and height of the crop window is retained during resizing
 * @property {boolean} preserveSize - if true, will not scale the resulting image to the croppedWidth/croppedHeight and will output the exact crop size from original
 * @property {Shape} shape - of the cropper tool
 */
export interface IMtCropperSettings {
    canvasHeight?: number;
    canvasWidth?: number;
    croppedHeight?: number;
    croppedWidth?: number;
    height?: number;
    width?: number;
    minHeight?: number;
    minWidth?: number;
    cropFromOriginalResolution?: boolean;
    hideFileInput?: boolean;
    keepAspect?: boolean;
    preserveSize?: boolean;
    shape?: Shape;
}
