import { Component, OnInit, EventEmitter, Output, Input, OnDestroy, ViewChild, ElementRef, AfterViewInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MonoTypeOperatorFunction, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface ISearchbarControlAPI {
    clearSearch: () => void;
    getSearchControl: () => FormControl;
    getSearchInputElement: () => ElementRef;
}

@Component({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MtSearchBarComponent),
        },
    ],
    selector: 'mt-search-bar',
    template: `
        <div>
            <div class="input-group">
                <input type="text" #searchInputElement [formControl]="searchControl" class="form-control" placeholder="Search" (focus)="touched()" />
                <span class="input-group-btn">
                    <button type="button" (click)="clearSearch()" class="btn btn-default btn-nohover btn-flat">
                        <i class="fa"></i>
                        <i class="fa fa-remove" aria-hidden="true"></i>
                        <i class="fa"></i>
                    </button>
                </span>
            </div>
        </div>
    `,
})
export class MtSearchBarComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
    // *** CONTROL VALUE ACCESSOR ***
    /* tslint:disable:member-ordering */
    writeValue(obj: any): void {
        this.searchControl.setValue(obj);
        this.searchControl.updateValueAndValidity();
    }
    protected onValueChanged: (value: any) => void;
    registerOnChange(fn: any): void {
        this.onValueChanged = fn;
    }
    protected onTouched: () => void;
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.searchControl.disable();
        } else {
            this.searchControl.enable();
        }
    }
    // *** END -- CONTROL VALUE ACCESSOR ***

    @Output('searchControlCreated')
    searchControlCreated: EventEmitter<FormControl> = new EventEmitter<FormControl>();
    @Output('onSearch')
    onSearch: EventEmitter<string> = new EventEmitter<string>();
    @Output('ready') onReady = new EventEmitter<ISearchbarControlAPI>();
    @ViewChild('searchInputElement', { static: false }) searchInputElement: ElementRef;

    currentSearchDelay: MonoTypeOperatorFunction<any>;
    private _searchDelay: number;
    @Input() set searchDelay(value: number) {
        this._searchDelay = value;
        this.currentSearchDelay = debounceTime(this._searchDelay);
    }
    get searchDelay(): number {
        return this._searchDelay;
    }

    searchControl = new FormControl();

    subscriptions = new Subscription();

    ngOnInit(): void {
        this.searchDelay = 550;
        this.subscriptions.add(
            this.searchControl.valueChanges
                .pipe((value) => this.currentSearchDelay(value)) // needed to make sure the value is reflected if changed after init
                .subscribe((value) => {
                    this.onSearch.emit(value);
                }),
        );
        this.subscriptions.add(
            this.searchControl.valueChanges.subscribe((value) => {
                this.onValueChanged?.(value);
            }),
        );
        this.searchControlCreated.emit(this.searchControl);
    }

    ngAfterViewInit(): void {
        this.onReady.emit({
            clearSearch: this.clearSearch.bind(this),
            getSearchControl: (() => this.searchControl).bind(this),
            getSearchInputElement: (() => this.searchInputElement).bind(this),
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    touched(): void {
        this.onTouched?.();
    }

    clearSearch(): void {
        this.searchControl.setValue('');
        this.onTouched?.();
    }
}
