import { SelectInputTypes } from '../form-elements.library';
import { CustomFormControlComponentBase } from '../custom-form-component.base';
import { Injector } from '@angular/core';

export class FormSelectBaseWithNullableOption extends CustomFormControlComponentBase {
    constructor(injector: Injector) {
        super(injector);
    }
    ngOnInit(): void {
        super.ngOnInit();

        switch (this.config.type.inputType) {
            case SelectInputTypes.Dropdown:
            case SelectInputTypes.MultiselectDropdown:
            case SelectInputTypes.OldStyleMultiselectDropdown:
                this.config.ensureNullableOption('');
                break;
            case SelectInputTypes.RadioButtonList:
            case SelectInputTypes.OldStyleRadioButtonList:
                this.config.ensureNullableOption('No Selection');
                break;
            case SelectInputTypes.TypeAhead:
            default:
                break;
        }
    }
}
