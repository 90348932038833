import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavService } from '../nav.service';

@Component({
    selector: 'app-footer',
    styles: [
        `
            .standard-text-color {
                color: #333;
            }
            .miles-blue-text-color {
                color: #004aa0;
            }
            .miles-link {
                cursor: pointer;
            }
            .miles-footer {
                color: black;
            }
        `,
    ],
    template: `
        <div *ngIf="showFooter" class="main-footer">
            <div *ngIf="footerHtml !== 'useDefaultMilesFooter'" class="container-fluid miles-footer" [innerHtml]="footerHtml"></div>
            <div *ngIf="footerHtml === 'useDefaultMilesFooter'" class="container-fluid miles-link" (click)="goToMilesSite()">
                <i>powered by</i>&nbsp;<strong class="miles-blue-text-color">MilesTechnologies.com</strong>
            </div>
        </div>
    `,
})
export class AppFooterComponent implements OnInit, OnDestroy {
    showFooter = true;
    footerHtml: string;

    subscriptions = new Subscription();

    protected navService: NavService;

    constructor(injector: Injector) {
        this.navService = injector.get(NavService);
    }

    ngOnInit(): void {
        this.subscriptions.add(this.navService.showFooter.subscribe((showFooter) => (this.showFooter = showFooter)));
        this.subscriptions.add(this.navService.footerHtml.subscribe((footerHtml) => (this.footerHtml = footerHtml)));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    goToMilesSite(): void {
        let milesSiteLink = 'https://www.milestechnologies.com/software-development';

        const siteName = this.navService.siteName;
        if (siteName) {
            milesSiteLink += `?utm_source=client&utm_medium=${siteName}`;
        }

        window.open(milesSiteLink);
    }
}
