import { Component, ErrorHandler, Injector, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthService } from '@mt-ng2/auth-module';
import { PageTitlesService } from '@mt-ng2/page-titles';

@Component({
    selector: 'app-admin-access',
    templateUrl: './admin-access.component.html',
})
export class AdminAccessComponent implements OnInit {
    resetKey: string;
    badKeyError = `Oops something went wrong!
    - Probably the key has been used or expired.
    - Or you did something you weren't supposed to do.
    - Your best bet is to just generate a new email.`;

    protected authService: AuthService;
    protected router: Router;
    public route: ActivatedRoute;
    protected notificationsService: NotificationsService;
    protected pageTitlesService: PageTitlesService;
    protected globalErrorHandler: ErrorHandler;

    constructor(injector: Injector) {
        this.authService = injector.get(AuthService);
        this.router = injector.get(Router);
        this.route = injector.get(ActivatedRoute);
        this.notificationsService = injector.get(NotificationsService);
        this.pageTitlesService = injector.get(PageTitlesService);
        this.globalErrorHandler = injector.get(ErrorHandler);
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.resetKey = params.resetKey;
        });
        this.authService.adminAccess(this.resetKey).subscribe(
            (answer) => {
                this.success();
                this.router.navigate(['/home']);
            },
            (error) => {
                if (error.status === 418) {
                    this.notificationsService.error('Access link is invalid');
                    console.error(this.badKeyError);
                } else if (error.status === 400) {
                    if (error.error) {
                        if (typeof error.error === 'string') {
                            this.error(error.error);
                        } else if (typeof error.error?.ModelState?.Service === 'string') {
                            this.error(error.error.ModelState.Service);
                        } else {
                            this.globalErrorHandler.handleError(error);
                        }
                    } else {
                        this.error('Something went wrong, Please generate a new access email');
                        console.error(this.badKeyError);
                    }
                }
            },
        );
        this.setPageTitle();
    }

    setPageTitle(): void {
        this.pageTitlesService.setPageTitle('Admin Access');
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error('Save Failed');
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Welcome Miles User!');
    }
}
